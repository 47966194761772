import styled from "styled-components";

export const ContainerImageChatInfo = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  > svg {
    width: 200px;
    height: 200px;
  }
  > div {
    font-size: 15px;
  }
`

export const ChatModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ListItemTextContainer = styled.section`
  > div {
    > span {
      font-weight: 900;
    }
  }
`;

export const ListItemTitle = styled.section`
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
`;

export const InformationChat = styled.section`
  padding: 18px;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  > svg {
    width: 200px;
    height: 200px;
  }
`;