import { Tooltip } from "@material-ui/core";
import { Facebook, Instagram, Telegram, WhatsApp } from "@material-ui/icons";

export const IconChannel = (channel, marginRight = "0px") => {
  return (
    <>
      {channel === "whatsapp" && (
        <Tooltip title={`WhatsApp`}>
          <WhatsApp
            fontSize="small"
            style={{ color: "#25D366", marginRight }}
          />
        </Tooltip>
      )}
      {channel === "telegram" && (
        <Tooltip title={`Telegram`}>
          <Telegram
            fontSize="small"
            style={{ color: "#0088cc", marginRight }}
          />
        </Tooltip>
      )}
      {channel === "instagram" && (
        <Tooltip title={`Direct`}>
          <Instagram
            fontSize="small"
            style={{ color: "#bc2a8d", marginRight }}
          />
        </Tooltip>
      )}
      {channel === "facebook" && (
        <Tooltip title={`Messeger`}>
          <Facebook
            fontSize="small"
            style={{ color: "#1877f2", marginRight }}
          />
        </Tooltip>
      )}
    </>
  );
};

export const isYouTubeLink = url => {
  const youtubeRegex =
    /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|embed)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  return youtubeRegex.test(url);
};

export const isLinkOrEmail = text => {
  const regex =
    /^(https?:\/\/[^\s/$.?#].[^\s]*|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  return regex.test(text);
};

export const formatTextWithLimit = (text, limit) => {
  if (typeof text !== "string" || text.length < limit || !limit) return text;
  return `${text.slice(0, limit)}...`;
};

export const typeMediaUrl = nomeDoArquivo => {
  const extensao = nomeDoArquivo.split(".").pop().toLowerCase();

  const tiposDeMidiaPorExtensao = {
    'jpg': 'Imagem', 'jpeg': 'Imagem', 'png': 'Imagem', 'gif': 'Imagem',
    'mp4': 'Vídeo', 'avi': 'Vídeo', 'mkv': 'Vídeo', 'mov': 'Vídeo', 'mpeg': 'Vídeo',
    'mp3': 'Áudio', 'ogg': 'Áudio', 'wav': 'Áudio', 
    'doc': 'Doc', 'docx': 'Doc', 'pdf': 'Doc', 'txt': 'Doc', 'rtf': 'Doc', 'ppt': 'Doc', 'pptx': 'Doc',
    'xls': 'Doc', 'xlsx': 'Doc', 'csv': 'Doc', 'odt': 'Doc', 'ods': 'Doc', 'odp': 'Doc',
  };

  return tiposDeMidiaPorExtensao[extensao] || "";
};

export const forbiddenFileTypes = () => [
  ".jsx",
  ".js",
  ".ts",
  ".dll",
  ".sys",
  ".php",
  ".exe",
  ".bin",
  ".cab",
  ".bat",
  ".cmd",
  ".sh",
  ".htm",
  ".html"
];
