import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Tooltip, TextField, Grid } from "@material-ui/core";
import { CloudUpload, Clear } from "@material-ui/icons";
import api from "../../services/api";
import { toast } from "react-toastify";
import useSettings from "../../hooks/useSettings";

const FileInput = ({ label, fileType }) => {
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState("");
  const fileInputRef = useRef(null);
  // eslint-disable-next-line
  const [settings, setSettings] = useState({});
  const { getAll: getAllSettings } = useSettings();
  const { update } = useSettings();

  useEffect(() => {
    async function findData() {
      try {
        const settingList = await getAllSettings();
        setSettings(settingList);

        if (Array.isArray(settingList) && settingList.length) {
          const efiCertificadoP12Type = settingList.find(
            s => s.key === "efiCertificadoP12"
          );
          if (efiCertificadoP12Type) {
            setFile(efiCertificadoP12Type.value);
            setFilename(efiCertificadoP12Type.value);
          }
        }
      } catch (e) {
        toast.error(e);
      }
    }
    findData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileChange = async e => {
    const selectedFile = e.target.files[0];
 
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("body", selectedFile.name);
    try {
      const response = await api.post("/upload-p12", formData);
      const { message, filename } = await response.data;
      setFilename(filename);
      toast.success(message);
      setFile(filename);

      await update({
        key: "efiCertificadoP12",
        value: filename
      });
    } catch (err) {
      console.error(err);
      toast.error("Ocorreu um erro ao enviar o arquivo.\n" + err);
    }
  };

  const handleClear = async () => {
    try {
      const response = await api.delete("/delete-p12", {
        data: { fileName: filename }
      });
      const message = response.data?.error
        ? response.data.message + "\n" + response.data?.error
        : response.data.message;

      if (!response.data?.error) {
        setFilename("");
        setFile(null);
      }
      toast.success(message);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={12} md={10}>
          <TextField
            fullWidth
            margin="dense"
            label={`${!file ? "Nenhum arquivo" : label}`}
            value={filename}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item>
          <input
            type="file"
            id="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
            accept={fileType}
          />
          {!file && (
            <Tooltip title="Selecionar arquivo">
              <IconButton onClick={() => fileInputRef.current.click()}>
                <CloudUpload />
              </IconButton>
            </Tooltip>
          )}
          {file && (
            <Tooltip title="Excluir arquivo">
              <IconButton onClick={handleClear} disabled={!file}>
                <Clear />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

FileInput.propTypes = {
  fileType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default FileInput;
