import React, { useContext, useEffect, useReducer, useState } from "react"; 
import { Link as RouterLink } from "react-router-dom";

import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge, makeStyles, Tooltip, Typography } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
// eslint-disable-next-line
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import EventIcon from "@material-ui/icons/Event";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
// import EventAvailableIcon from "@material-ui/icons/EventAvailable";
// import ExpandLessIcon from "@material-ui/icons/ExpandLess";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PeopleIcon from "@material-ui/icons/People";
import ListIcon from "@material-ui/icons/ListAlt";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import ForumIcon from "@material-ui/icons/Forum";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { DeviceHubOutlined } from '@material-ui/icons';

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { socketConnection } from "../services/socket";
import { isArray } from "lodash";
import api from "../services/api";
import toastError from "../errors/toastError";
import usePlans from "../hooks/usePlans";
import ToggleButton from "@material-ui/lab/ToggleButton"; 

const useStyles = makeStyles((theme) => ({ 
  button: {
    height: 35,
    width: 35,
    border: "1px solid",
    borderColor: "#aaa",
    borderRadius: 8,
    marginRight: 8,
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      borderColor: theme.mode === "light" ? theme.palette.primary.main : "#FFF",
      border: "2px solid",
      transform: "scale(1.3)",
    }
  },
  icon: {
    // color: "#aaa",
    transition: "transform 0.3s ease-in-out",
    "&:hover": { 
      transform: "scale(1.2)",
    }
  }, 
  tabsBadge: {
    position: "absolute", // Certifique-se de que o badge está posicionado de forma absoluta
    right: "-20px", // Posiciona o badge à direita do botão
    top: "50%", // 
    transform: "translateY(-50%)", // Centraliza verticalmente
    whiteSpace: "nowrap",
    borderRadius: "12px",
    padding: "0 8px", // Faz o badge um pouco mais largo
    backgroundColor: theme.mode === "light" ? theme.palette.primary.main : "#FFF", // Cor de fundo branca
    color: theme.mode === "light" ? "#FFF" : theme.palette.primary.main, // Texto na cor primária
    border: `1px solid ${theme.palette.primary.main}`, // Borda na cor primária
    transition: "opacity 0.3s ease, transform 0.3s ease", // Transição para efeito suave 
    pointerEvents: "none", // Evita interação com o mouse
  },
  tabsBadgeDefault: {
    top: "105%",
    right: "50%",
    transform: "translate(45%, 0)",
    whiteSpace: "nowrap",
    borderRadius: "12px",
    padding: "0 8px",
    backgroundColor:
      theme.mode === "light" ? theme.palette.primary.main : "#FFF",
    color: theme.mode === "light" ? "#FFF" : theme.palette.primary.main
  }, 
  listItem: {
    height: "44px",
    width: "auto",
    "&:hover $iconHoverActive": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  listItemText: {
    fontSize: "14px",
    color: theme.mode === "light" ? "#666" : "#FFF",
  },
  avatarActive: {
    backgroundColor: "transparent",
  },
  avatarHover: {
    backgroundColor: "transparent",
  },
  iconHoverActive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    height: 36,
    width: 36,
    backgroundColor: theme.mode === "light" ? "rgba(120,120,120,0.1)" : "rgba(120,120,120,0.5)",
    color: theme.mode === "light" ? "#666" : "#FFF",
    // color: theme.mode === "light" ? theme.palette.primary.main : "#FFF",
    "&:hover, &.active": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.4rem",
    },
  },
}));
 
function ListItemLink({ icon, primary, to, showCaption }) { 
  const classes = useStyles(); 

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to]
  ); 
 
  return (
    <>
      <li style={{ 
          position: "relative", 
          display: 'flex', 
          alignItems: 'center',
          marginBottom: '5px' 
        }} 
      >
        {!showCaption ? ( 
          <Tooltip 
            title={primary} 
            placement="right"
          >
            <ToggleButton
              value={primary}
              className={classes.button}
              component={renderLink}  
            > 
              {icon}
            </ToggleButton> 
          </Tooltip>           
        ) : (
          <>
            <ToggleButton
              value={primary}
              className={classes.button}
              component={renderLink}  
            > 
              {icon}
            </ToggleButton> 
            {showCaption && (
              <Typography 
                className={classes.listItemText} 
                style={{ marginLeft: '2px' }}
                component={renderLink}  
              >
                {primary}
              </Typography>
            )}
          </> 
        )}
      </li>
    </>
  );  
}
   
const reducer = (state, action) => {
  if (action.type === "LOAD_CHATS") {
    const chats = action.payload;
    const newChats = [];

    if (isArray(chats)) {
      chats.forEach((chat) => {
        const chatIndex = state.findIndex((u) => u.id === chat.id);
        if (chatIndex !== -1) {
          state[chatIndex] = chat;
        } else {
          newChats.push(chat);
        }
      });
    }

    return [...state, ...newChats];
  }

  if (action.type === "UPDATE_CHATS") {
    const chat = action.payload;
    const chatIndex = state.findIndex((u) => u.id === chat.id);

    if (chatIndex !== -1) {
      state[chatIndex] = chat;
      return [...state];
    } else {
      return [chat, ...state];
    }
  }

  if (action.type === "DELETE_CHAT") {
    const chatId = action.payload;

    const chatIndex = state.findIndex((u) => u.id === chatId);
    if (chatIndex !== -1) {
      state.splice(chatIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  if (action.type === "CHANGE_CHAT") {
    const changedChats = state.map((chat) => {
      if (chat.id === action.payload.chat.id) {
        return action.payload.chat;
      }
      return chat;
    });
    return changedChats;
  }
};

const MainListItems = ({ drawerClose, showCaption }) => { 
  const classes = useStyles();

  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext); 
  const [connectionWarning, setConnectionWarning] = useState(false); 
  const [showCampaigns, setShowCampaigns] = useState(false); 
  const [showUseApi, setShowUseApi] = useState(false); 

  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam] = useState("");
  const [chats, dispatch] = useReducer(reducer, []);   
  const [useIntegration, setUseIntegration] = useState(false);
  const { finder: listPlans } = usePlans(); 
   
  useEffect(() => {
    async function fetchData() {
      if (user && user.company) {
        const planData = await listPlans(user.company.planId);
        setUseIntegration(planData.useIntegration || false);
      } else 
        setUseIntegration(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
   
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId }); 

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === "new-message") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
      if (data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    if (unreadsCount > 0) {
      setInvisible(false);
    } else {
      setInvisible(true);
    }
  }, [chats, user.id]);

  useEffect(() => {
    if (localStorage.getItem("cshow")) {
      setShowCampaigns(true);
    }
    if (localStorage.getItem("useApi")) {
      setShowUseApi(true);
    }
    
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div onClick={drawerClose}>
      {/* <>
        <Divider />
        {showCaption && (<ListSubheader inset>
            {i18n.t("mainDrawer.listItems.operational")}
          </ListSubheader>
        )}
      </> */}

      <Can
        role={user.profile}
        perform="dashboard:view"
        yes={() => (
          <ListItemLink
            to="/"
            primary={i18n.t("mainDrawer.listItems.dashboard")} 
            showCaption={showCaption}
            icon={
              <DashboardOutlinedIcon className={classes.icon} />
            }
          />
        )}
      />

      <Divider />

      <ListItemLink
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")} 
        showCaption={showCaption}
        icon={
          <WhatsAppIcon className={classes.icon} />
        }        
      />
   
 
      <ListItemLink
        to="/quick-messages"
        primary={i18n.t("mainDrawer.listItems.quickMessages")}
        showCaption={showCaption}
        icon={ 
          <FlashOnIcon className={classes.icon} />     
        }
      />
  
      <ListItemLink
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        showCaption={showCaption}
        icon={<ContactPhoneOutlinedIcon className={classes.icon} />}
      />

      <ListItemLink
        to="/schedules"
        primary={i18n.t("mainDrawer.listItems.schedules")}
        showCaption={showCaption}
        icon={<EventIcon className={classes.icon} />}
      />

      <ListItemLink
        to="/tags"
        primary={i18n.t("mainDrawer.listItems.tags")}
        showCaption={showCaption}
        icon={<LocalOfferIcon className={classes.icon}/>}
      />

      <ListItemLink
        to="/chats"
        primary={i18n.t("mainDrawer.listItems.chats")}
        showCaption={showCaption}
        icon={
          <Badge color="secondary" variant="dot" invisible={invisible}>
            <ForumIcon className={classes.icon}/>
          </Badge>
        }
      />

      <ListItemLink
        to="/helps"
        primary={i18n.t("mainDrawer.listItems.helps")}
        showCaption={showCaption}
        icon={<HelpOutlineIcon className={classes.icon}/>}
      /> 

      <Divider />
      {showCaption && (user.profile === 'user') && (
        <ListSubheader inset>
          {i18n.t("mainDrawer.listItems.administration")}
        </ListSubheader>     
      )}     

      <ListItemLink
        to="/connections"
        primary={i18n.t("mainDrawer.listItems.connections")}
        showCaption={showCaption}
        icon={
          <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
            <SyncAltIcon className={classes.icon}/>
          </Badge>
        }
      />                      

      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider />
            {showCaption && (<ListSubheader inset>
                {i18n.t("mainDrawer.listItems.campaigns")}
              </ListSubheader>   
            )}         
              {showCampaigns && (
                <> 
                  <ListItemLink
                    to="/campaigns"
                    primary={i18n.t("mainDrawer.listItems.campaignsList")}
                    showCaption={showCaption}
                    icon={<ListIcon className={classes.icon}/>}
                  />
                  <ListItemLink
                    to="/contact-lists" 
                    primary={i18n.t("mainDrawer.listItems.campaignsListContacts")}
                    showCaption={showCaption}
                    icon={<PeopleIcon className={classes.icon}/>}
                  />
                  <ListItemLink
                    to="/campaigns-config"
                    primary={i18n.t("mainDrawer.listItems.campaignsSettings")}
                    showCaption={showCaption}
                    icon={<SettingsOutlinedIcon className={classes.icon}/>}
                  />                  
                </>
              )}          


            <Divider />
            {showCaption && (<ListSubheader inset>
                {i18n.t("mainDrawer.listItems.administration")}
              </ListSubheader>     
            )}     
       
            {user.super && (
              <ListItemLink
                to="/announcements"
                primary={i18n.t("mainDrawer.listItems.annoucements")}
                showCaption={showCaption}
                icon={<AnnouncementIcon className={classes.icon}/>}
              />
            )}
            {(useIntegration && useIntegration === true) && (
              <ListItemLink
                to="/integrations"
                primary={i18n.t("mainDrawer.listItems.queueIntegration")}
                showCaption={showCaption}
                icon={<DeviceHubOutlined className={classes.icon}/>}
              />
            )}
            <ListItemLink
              to="/queues"
              primary={i18n.t("mainDrawer.listItems.queues")}
              showCaption={showCaption}
              icon={<AccountTreeOutlinedIcon className={classes.icon}/>}
            />
            <ListItemLink
              to="/users"
              primary={i18n.t("mainDrawer.listItems.users")}
              showCaption={showCaption}
              icon={<PeopleAltOutlinedIcon className={classes.icon}/>}
            />
            {(user.super || showUseApi ) && (
              <ListItemLink
                to="/apidocs" 
                primary={i18n.t("mainDrawer.listItems.messagesAPI")}
                showCaption={showCaption}
                icon={<CodeRoundedIcon className={classes.icon}/>}
              />
            )}
            <ListItemLink
              to="/invoices"
              primary={i18n.t("mainDrawer.listItems.invoices")}
              showCaption={showCaption}
              icon={<LocalAtmIcon className={classes.icon}/>} 
            />
            <ListItemLink
              to="/settings"
              primary={i18n.t("mainDrawer.listItems.settings")}
              showCaption={showCaption}
              icon={<SettingsOutlinedIcon className={classes.icon}/>}
            />
{/*             <ListItemLink
              to="/subscription"
              primary="Assinatura"
              icon={<PaymentIcon />}
              //className={classes.menuItem}
            /> */}
          </>
        )}
      />
    </div>
  );
};

export default MainListItems;
