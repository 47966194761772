import React, { useEffect, useState } from "react"; 
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  iframeContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
  },
}));

const Typebot = () => {
  const classes = useStyles();
  const [urlPage, setUrlPage] = useState(null); 

  useEffect(() => {
    setUrlPage(process.env.REACT_APP_INTEGRATION_TYPEBOT_URL);   
  }, []);



  return (
    <div className={classes.iframeContainer}>
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        className={classes.iframe}
        src={urlPage}
      />
    </div>
  );
};

export default Typebot;
