import React, { useContext, useEffect } from "react";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import Tickets from "../TicketsCustom"
import TicketAdvanced from "../TicketsAdvanced";
import useAuth from "../../hooks/useAuth.js";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext.js";

function TicketResponsiveContainer (props) {
    const history = useHistory();

    const { user } = useContext(AuthContext);    

	const { getStatusAndDueDateAssignature: getStatusCompany } = useAuth();

	useEffect(() => {
	  async function findData() {
		try {
		  const status = await getStatusCompany();
		  if (!status) {
			toast.warn("A plataforma requer atenção do administrador!");
			setTimeout(() => {
			  if (user.profile === "admin")
				history.push("/invoices");
			  else
				history.push("/helps");
			}, 1000);
		  }
		} catch (e) {
		  toast.error(e);
		}    
	  }
	  findData();
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);        
      
    if (isWidthUp('md', props.width)) {
        return <Tickets />;    
    }
    return <TicketAdvanced />
}

export default withWidth()(TicketResponsiveContainer);