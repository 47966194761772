import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import toastError from "../../errors/toastError";
import api from "../../services/api";

import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { AuthContext } from "../../context/Auth/AuthContext";

import { Button, Divider, useTheme, } from "@material-ui/core"; 
import NewTicketModal from "../../components/NewTicketModal";
import ContactModal from "../../components/ContactModal";
import { i18n } from '../../translate/i18n';

const VcardPreview = ({ contact, numbers, ticket, isQuotedMsgRender }) => {
    const history = useHistory();
    const theme = useTheme();

    // eslint-disable-next-line
    const currentTicket = ticket;
    const { user } = useContext(AuthContext); 
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false); 
    const [contactModalOpen, setContactModalOpen] = useState(false);

    const [contactData, setContactData] = useState({
        name: "",
        number: 0,
        email: ""
    }); 
    
    const [selectedContact, setContact] = useState({
        id: "",
        name: "",
        number: 0,
        profilePicUrl: ""
    });
 
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async() => {
                try {
                    const searchParam = numbers.replace(/\D/g, ""); 
                    const pageNumber = 1;
                    let { data } = await api.get("/contacts/", {
                      params: { searchParam, pageNumber },
                    }); 
                    if (data.contacts.length > 0) { 
                        setContact(data.contacts[0]);  
                    } 
                } catch (err) { 
                  toastError(err); 
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [numbers]);

    // eslint-disable-next-line
    const handleNewChat = async() => {
        try {
            const { data: ticket } = await api.post("/tickets", {
                contactId: selectedContact.id,
                userId: user.id,
                status: "open",
            }); 
            history.push(`/tickets/${ticket.uuid}`);
        } catch (err) {
            toastError(err);
        }
    }
     
    const handleCloseOrOpenTicket = (ticket) => {
        setNewTicketModalOpen(false);
        if (ticket !== undefined && ticket.uuid !== undefined) {
          history.push(`/tickets/${ticket.uuid}`);
        }
      };
      
  const setNewContact = (contact, numbers) => {
    setContactModalOpen(false);
    const newContactData = {name: contact, number: numbers.replace(/\D/g, ""), email: ""}
    setContactData(newContactData);
    setContactModalOpen(true);
  };   
  
  const handleCloseContactModal = () => {
    setContactModalOpen(false);
  };
  
  const handleSaveContactModal = (contactSaved) => { 
    if (!contactSaved) return;
    const newContactData = {
      id: contactSaved.id,
      name: contactSaved.name, 
      number: contactSaved.number, 
      profilePicUrl: contactSaved.profilePicUrl,
    }
    setContact(newContactData); 
  };
  
  const defaultImagePath = require("../../assets/nopicture.png").default;
  const DefaultImageComponent = () => 
    <img 
      //src={selectedContact.profilePicUrl ?? defaultImagePath} 
      src={defaultImagePath} 
      alt="default" 
      style={{ width: "100%", height: "100%", objectFit: "cover" }} 
    />;

    return (
		<>
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                initialContact={selectedContact}
                onClose={(ticket) => {
                    handleCloseOrOpenTicket(ticket);
                }}
            ></NewTicketModal>
          <ContactModal
            open={contactModalOpen}
            onClose={handleCloseContactModal} 
            onSave={(contactSaved) => {
                handleSaveContactModal(contactSaved);
            }}
            aria-labelledby="form-dialog-title"
            contactId={null}
            initialValues={contactData}
          ></ContactModal>
          
			<div style={{
				minWidth: "250px",
			}}>
				<Grid container spacing={1}>
					<Grid item xs={2}>
						<Avatar>
              <DefaultImageComponent />
            </Avatar>
					</Grid>
					<Grid item xs={9}>
					    {selectedContact.number === 0 ?
					    <Typography style={{ marginTop: "12px", marginLeft: "10px" }} variant="subtitle1" color="secondary" gutterBottom>
							{contact}
						</Typography> : null}
						
						<Typography style={{ marginTop: "12px", marginLeft: "10px" }} variant="subtitle1" color="secondary" gutterBottom>
							{(selectedContact && selectedContact.name) ? selectedContact.name : 
							    (selectedContact.number > 0 ? selectedContact.number : numbers)}
						</Typography>
					</Grid>
          {!isQuotedMsgRender && (
            <Grid item xs={12}>
              <Divider />
              <Button
                fullWidth 
                color={theme.palette.type === 'dark' ? 'secondary' : 'primary'}
                variant="outlined"
                //onClick={handleNewChat}
                              onClick={ 
                                  selectedContact.number === 0
                                    ? () => setNewContact(contact, numbers)
                                    : () => setNewTicketModalOpen(true)
                                }
            // 			disabled={!selectedContact.number}
              >
                  {selectedContact.number > 0 ? `${i18n.t("newTicketModal.title")}` : "Salvar contato"}
              </Button>
            </Grid>
          
          )}
        </Grid>
			</div>
		</>
	);

};

export default VcardPreview;