import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Grid,
  FormControlLabel, 
  Paper
} from "@material-ui/core";
 
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import IOSSwitch from "../IOSSwitch";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  },

  btnWrapper: {
    position: "relative"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Muito curto!")
    .max(50, "Muito longo!")
    .required("Obrigatório"),
  password: Yup.string().min(5, "Muito curto!").max(50, "Muito longo!"),
  email: Yup.string().email("Email inválido").required("Obrigatório")
});

const UserModal = ({ open, onClose, userId }) => {
  const classes = useStyles();

  const initialState = {
    status: true,
    name: "",
    email: "",
    password: "",
    profile: "user"
  };

  const { user: loggedInUser } = useContext(AuthContext);

  const [user, setUser] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) return;
      try {
        const { data } = await api.get(`/users/${userId}`);
        setUser(prevState => {
          return { ...prevState, ...data };
        }); 
        const userQueueIds = data.queues?.map(queue => queue.id);
        setSelectedQueueIds(userQueueIds);
      } catch (err) {
        toastError(err);
      }
    };

    fetchUser();
  }, [userId, open]);

  useEffect(() => {

  }, [user]);
  
  const handleChangeStatus = (event, currentUser) => {
    if (loggedInUser.profile.toUpperCase() === 'ADMIN' && loggedInUser.id === userId) {
      toast.warn("Não é possível um administrador inativar seu próprio usuário!", { autoClose: 7000 });
      return;
    }

    const { checked } = event.target; 
    setUser(prevState => {
      return { ...prevState, status: checked === true };
    });
  };

  const handleClose = () => {
    onClose();
    setUser(initialState);
  };

  const handleSaveUser = async values => {
    const userData = { ...values, queueIds: selectedQueueIds };
    let error = false;
    try {      
      if (userId) {
        await api.put(`/users/${userId}`, userData);
      } else {
        await api.post("/users", userData);
      }
      toast.success(i18n.t("userModal.success"));
    } catch (err) {
      error = true;
      toastError(err);
    }
    if (!error)
      handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        // fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {userId
            ? `${i18n.t("userModal.title.edit")}`
            : `${i18n.t("userModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={user}
          enableReinitialize={true}
          validationSchema={UserSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveUser(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <Paper square className={classes.mainPaper} elevation={1}>
                <DialogContent dividers>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} xl={4} >
                      <FormControl 
                        variant="outlined"
                        className={classes.selectContainer}
                        margin="dense"
                        fullWidth                    
                      >
                        <Can
                          role={loggedInUser.profile}
                          perform="user-modal:editProfile"
                          yes={() => (
                            <>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    name="status"
                                    checked={user.status}
                                    onChange={(event) => handleChangeStatus(event, user)}
                                  />
                                }
                                label={"Status"}
                              /> 
                            </>
                          )}
                        />
                      </FormControl> 
                    </Grid>
                    <Grid item xs={12} md={6} xl={6} >
                      <FormControl
                        variant="outlined"
                        className={classes.selectContainer}
                        margin="dense"
                        fullWidth
                      >
                        <Can
                          role={loggedInUser.profile}
                          perform="user-modal:editProfile"
                          yes={() => (
                            <>
                              <InputLabel id="profile-selection-input-label">
                                {i18n.t("userModal.form.profile")}
                              </InputLabel>

                              <Field
                                as={Select}
                                label={i18n.t("userModal.form.profile")}
                                name="profile"
                                labelId="profile-selection-label"
                                id="profile-selection"
                                required
                              >
                                <MenuItem value="admin">Administrador</MenuItem>
                                <MenuItem value="user">Operador</MenuItem>
                              </Field>
                            </>
                          )}
                        />
                      </FormControl>                  
                    </Grid>
                    <Grid item xs={12} md={12} xl={6} >
                      <FormControl
                          variant="outlined"
                          className={classes.selectContainer}
                          margin="dense"
                          fullWidth
                      >
                        <Field
                          as={TextField}
                          label={i18n.t("userModal.form.name")}
                          autoFocus
                          name="name"
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                          variant="outlined"
                          margin="dense" 
                        /> 
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6} >
                      <FormControl
                            variant="outlined"
                            className={classes.selectContainer}
                            margin="dense"
                            fullWidth
                        >
                          <Field
                          as={TextField}
                          label={i18n.t("userModal.form.email")}
                          name="email"
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          variant="outlined"
                          margin="dense" 
                        /> 
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6} >
                      <FormControl
                          variant="outlined"
                          className={classes.selectContainer}
                          margin="dense"
                          fullWidth
                      >
                        <Field
                          as={TextField}
                          label={i18n.t("userModal.form.password")}
                          type="password"
                          name="password"
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                          variant="outlined"
                          margin="dense" 
                        />    
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} xl={6} >      
                      <FormControl
                        variant="outlined"
                        className={classes.selectContainer}
                        margin="dense"
                        fullWidth
                      >   
                        <Can
                          role={loggedInUser.profile}
                          perform="user-modal:editQueues"
                          yes={() => (
                            <QueueSelect
                              selectedQueueIds={selectedQueueIds}
                              onChange={values => setSelectedQueueIds(values)}
                            />
                          )}
                        />
                      </FormControl>     
                    </Grid>
                  </Grid>
                </DialogContent>
              </Paper>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("userModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {userId
                    ? `${i18n.t("userModal.buttons.okEdit")}`
                    : `${i18n.t("userModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default UserModal;
