import React, { useState, useEffect, useReducer, useContext } from "react";
import { toast } from "react-toastify";
import { socketConnection } from "../../services/socket"; 
import { makeStyles } from "@material-ui/core/styles";

import {
  Avatar,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from "@material-ui/core";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';

import SearchIcon from "@material-ui/icons/Search";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import IntegrationModal from "../../components/QueueIntegrationModal";
import ConfirmationModal from "../../components/ConfirmationModal";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useHistory } from "react-router-dom";
 
const reducer = (state, action) => {
  if (action.type === "LOAD_INTEGRATIONS") {
    const queueIntegration = action.payload;
    const newIntegrations = [];

    queueIntegration.forEach((integration) => {
      const integrationIndex = state.findIndex((u) => u.id === integration.id);
      if (integrationIndex !== -1) {
        state[integrationIndex] = integration;
      } else {
        newIntegrations.push(integration);
      }
    });

    return [...state, ...newIntegrations];
  }

  if (action.type === "UPDATE_INTEGRATIONS") {
    const queueIntegration = action.payload;
    const integrationIndex = state.findIndex((u) => u.id === queueIntegration.id);

    if (integrationIndex !== -1) {
      state[integrationIndex] = queueIntegration;
      return [...state];
    } else {
      return [queueIntegration, ...state];
    }
  }

  if (action.type === "DELETE_INTEGRATION") {
    const integrationId = action.payload;

    const integrationIndex = state.findIndex((u) => u.id === integrationId);
    if (integrationIndex !== -1) {
      state.splice(integrationIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  }, 
}));

const QueueIntegration = () => {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [queueIntegration, dispatch] = useReducer(reducer, []);
  const { user } = useContext(AuthContext);
  const companyId = user.companyId;

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchIntegrations = async () => {
        try {
          const { data } = await api.get("/queueIntegration/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_INTEGRATIONS", payload: data.queueIntegrations });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchIntegrations();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => { 
    const socket = socketConnection({ companyId, userId: user.id });

    socket.on(`company-${companyId}-queueIntegration`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_INTEGRATIONS", payload: data.queueIntegration });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_INTEGRATION", payload: +data.integrationId });
      }
    });

    return () => {
      socket.disconnect();
    };
  // eslint-disable-next-line
  }, []);

  const handleOpenUserModal = () => {
    setSelectedIntegration(null);
    setUserModalOpen(true);
  };

  const handleCloseIntegrationModal = () => {
    setSelectedIntegration(null);
    setUserModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditIntegration = (queueIntegration) => {
    setSelectedIntegration(queueIntegration);
    setUserModalOpen(true);
  };

  const handleDeleteIntegration = async (integrationId) => {
    try {
      await api.delete(`/queueIntegration/${integrationId}`);
      toast.success("Registro excluído com sucesso!");
    } catch (err) {
      toastError(err);
    } 
    setDeletingUser(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  // eslint-disable-next-line
  const handleGoIntegration = (integration) => {
    if (integration.type === "n8n") 
      window.open(process.env.REACT_APP_INTEGRATION_N8N_URL, '_blank'); 
    if (integration.type === "typebot")
      history.push("/Typebot");
      // window.open(process.env.REACT_APP_INTEGRATION_TYPEBOT_URL, '_blank');
  };  
  
  const handleIconIntegration = (type, id) => {
    // const images = {
    //   asaas: require('../../assets/asaas.png'),
    //   logo: require('../../assets/logo.png'),
    //   'mk-auth': require('../../assets/mk-auth.png'),
    //   ixc: require('../../assets/ixc.png'),
    //   openai: require('../../assets/openai.png'),
    //   typebot: require('../../assets/typebot.png'),
    //   n8n: require('../../assets/n8n.png'),
    //   webhook: require('../../assets/webhook.png'),          
    //   nopicture: require('../../assets/nopicture.png'),
    // };    

    try { 
      // const imagePath = images[type] || images['nopicture']; 
      
      const imagePath = require(`../../assets/${type}.png`); 
        
      const ImageComponent = () => <img src={imagePath} alt={type} style={{ width: "100%", height: "100%", objectFit: "cover" }} />;
      return (
        <Tooltip title={`${type}: ${id}`} placement="top">
          <Avatar>
            <ImageComponent />
          </Avatar>
        </Tooltip>
      );
    } catch (error) {
      console.error(`Erro ao carregar a imagem para o tipo ${type}:`, error);
  
      const defaultImagePath = require('../../assets/nopicture.png'); //images['nopicture'];
      const DefaultImageComponent = () => <img src={defaultImagePath} alt="default" style={{ width: "100%", height: "100%", objectFit: "cover" }} />;
      return (
        <Tooltip title={`${type}: ${id}`} placement="top">
          <Avatar>
            <DefaultImageComponent />
          </Avatar>
        </Tooltip>
      );
    }
  };
  
  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingUser &&
          `${i18n.t("queueIntegration.confirmationModal.deleteTitle")} ${deletingUser.name}?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteIntegration(deletingUser.id)}
      >
        {i18n.t("queueIntegration.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <IntegrationModal
        open={userModalOpen}
        onClose={handleCloseIntegrationModal}
        aria-labelledby="form-dialog-title"
        currentId={selectedIntegration && selectedIntegration.id}
        companyId={companyId}
      />
      <MainHeader>
        <Title>{i18n.t("queueIntegration.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("queueIntegration.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          /> 
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenUserModal}
          >
            {i18n.t("queueIntegration.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              {/* <TableCell padding="checkbox"></TableCell> */}
              {/* <TableCell align="left">{i18n.t("queueIntegration.table.id")}</TableCell> */}
              <TableCell align="left">{i18n.t("queueIntegration.table.type")}</TableCell>              
              <TableCell align="left">{i18n.t("queueIntegration.table.name")}</TableCell>
              <TableCell align="center">{i18n.t("queueIntegration.table.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {queueIntegration.map((integration) => (
                <TableRow key={integration.id}>
                  {/* <TableCell align="left">{integration.id}</TableCell> */}
                  <TableCell align="left"> 
                    {handleIconIntegration(integration.type, integration.id)} 
                  </TableCell>                  
                  <TableCell align="left">{integration.name}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditIntegration(integration)}
                    >
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingUser(integration);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>

                    {/* {integration.type === 'typebot' || integration.type === 'n8n' ? (
                      <IconButton
                        size="small"
                        onClick={() => handleGoIntegration(integration)}
                      >
                        <OpenInNewOutlinedIcon />
                      </IconButton>
                    ) : null} */}
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={7} />}
            </>
          </TableBody>
					<TableFooter>
						<TableRow>
							<TableCell align="left">
								{i18n.t("table.totalRecords") + (queueIntegration ? queueIntegration?.length : 0)}
							</TableCell>  
						</TableRow>
					</TableFooter>    
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default QueueIntegration;