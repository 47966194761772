import api from "../../services/api";

const useCampaigns = () => {
  const statsInitial = {
    limitOfCampaignBy: null,
    numberOfCampaigns: null,
    numberOfGroupsCampaigns: null,
    numberOfContactsInGroupsCampaigns: null,
    valueOfResourceCampaigns: null,
    stats: {
      campaigns: 0,
      groupsCampaigns: 0,
      contactsInGroupsCampaigns: []
    }
  };

  const findPermissions = async () => {
    const { data } = await api.request({
      url: `/campaigns/stats`,
      method: "GET"
    });
    return data ? data : statsInitial;
  };

  return {
    findPermissions
  };
};

export default useCampaigns;
