// eslint-disable-next-line
import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import usePlans from "../../hooks/usePlans";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Checkbox, FormControlLabel, InputLabel, MenuItem, Select } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { i18n } from "../../translate/i18n";
import { openApi } from "../../services/api";
import moment from "moment";
import { cnpj } from "cpf-cnpj-validator"; 
import logo from '../../assets/logo.png';
import ConfirmationModal from "../../components/ConfirmationModal";
 
import toastError from "../../errors/toastError"; 
import { Link as MUILink } from "@material-ui/core";
// import { useLocation } from "react-router-dom";

const validator = require("email-validator");
 
const useStyles = makeStyles(theme => ({ 
    labelCustom: {
      color: "grey",
    },    
  paper: {
    marginTop: ({ isInsideIframe }) =>
      isInsideIframe ? theme.spacing(1) : theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },  
  form: { 
    width: "100%",
    marginTop: theme.spacing(3), 
    overflowY: "scroll",
    ...theme.scrollbarStyles
  },
  submitContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(-1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "200px",
    height: "50px",
    backgroundColor: "#000",
    borderRadius: "8px",
    color: "#fff"
  },
  // scrollableContainer: {
  //   height: "80%", //"630px",
  //   overflow: "auto",
  // },
  containerWithScroll: {
    height: "80%", 
    // flex: 1,
    // padding: theme.spacing(1),
    overflowY: "auto", //scroll 
    ...theme.scrollbarStyles,
  },  
  field: {
    marginBottom: theme.spacing(-1)
  }
}));

const Copyright = () => {
    const classes = useStyles();
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            <Link
                className={classes.labelCustom}
                href={process.env.REACT_APP_LANDING_PAGE_URL} 
            > 
                {"© "} {new Date().getFullYear()} {process.env.REACT_APP_COPYRIGHT}
            </Link>
        </Typography>
    );
};

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^(?!.*teste)/i, "Valor inválido")
    .min(2, "Muito curto!")
    .max(50, "Muito longo!")
    .required("Obrigatório"),
  vat: Yup.string()
    .matches(/^(?!.*teste)/i, "Valor inválido")
    .min(13, "Muito curto!")
    .max(19, "Muito longo!")
    .required("Obrigatório"),
  password: Yup.string()
    .matches(/^(?!.*teste)/i, "Valor inválido")
    .min(5, "Muito curto!")
    .max(50, "Muito longo!")
    .required("Obrigatório"),
  namePersonal: Yup.string()
    .matches(/^(?!.*teste)/i, "Valor inválido")
    .min(6, "Muito curto!")
    .max(50, "Muito longo!")
    .required("Obrigatório"),
  email: Yup.string()
    .matches(/^(?!.*teste)/i, "Valor inválido")
    .email("Email inválido")
    .required("Obrigatório"),
  phone: Yup.string()
    .matches(
      /^(\d{2}\s)?\d{4,5}-?\d{4}$/,
      "Número de telefone inválido. Utilize o formato 99 99999-9999."
    )
    .matches(
      /^(?!.*teste)/i,
      "Número de telefone inválido. Utilize o formato 99 99999-9999."
    )
    .min(7, "Muito curto!")
    .max(13, "Muito longo!")
    .required("Obrigatório")
});

const UserSchemaEmpty = Yup.object().shape({});

const SignUp = () => {
  // const location = useLocation();
  const isInsideIframe = window.self !== window.top;
  const validationSchema = isInsideIframe ? UserSchemaEmpty : UserSchema;

  const classes = useStyles({ isInsideIframe });
  const history = useHistory();

  const [showResendModal, setShowResendModal] = useState(false);
  const [dataResend, setDataResend] = useState({});
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [plans, setPlans] = useState([]);
  const { listAllPublished: listPlans } = usePlans();

  const initialState = {
    vat: "",
    name: "",
    phone: "",
    namePersonal: "",
    email: "",
    password: "",
    planId: ""
  };

  const [user] = useState(initialState);

  const handleSignUp = async values => {
    const inputValue = values.vat.replace(/\D/g, "");
    const maskedValue = formatValue(inputValue);

    if (!values.vat || !validateVat(values.vat)) {
      toast.error("CNPJ inválido, verifique!");
      return;
    }
    const isValidEmail = validator.validate(values.email);
    if (!isValidEmail) {
      toast.error("O endereço de email é inválido!");
      return;
    }
    if (values.planId === "") {
      if (isInsideIframe) 
        Object.assign(values, { planId: user.planId });
      else {
        toast.error(
          "Escolha um plano baseado na quantidade de atendentes que precisa!"
        );
        return;
      }
    }

    // ajustes do obj 
    Object.assign(values, { vat: maskedValue });
    Object.assign(values, { recurrence: "MENSAL" });    

    try {
      const lead = await openApi.post("/leads-web", values);
      if (lead.status === 201 && lead.data && lead.data?.id !== undefined) {
        try {
          const sendedEmail = await sendActivationEmail( 
            values.name,
            values.namePersonal,
            values.email
          );

          if (
            sendedEmail &&
            sendedEmail?.status === 200 &&
            sendedEmail?.data.result === true
          ) { 
            await handleSaveLinkFull(sendedEmail.data.linkFull, values.email);            

            toast.info(i18n.t("signup.toasts.success"), {
              autoClose: 5000,
              onClose: () => {
                if (isInsideIframe) window.location.reload();
                else history.push("/login");
              }
            });
          } else {
            toastError(sendedEmail.data.message);
          }
        } catch (error) {
          toastError(
            `Erro ao enviar o email com código, tente novamente mais tarde. ${error}`
          );
        }
      } else toastError(lead);
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  };

  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked); 
  };

  const sendActivationEmail = async (name, namePersonal, email) => {
    try {
      // Cria o token com o código de ativação e o prazo de validade
      const tokenData = await openApi.post("/auth/tokenSignup", {name, email});
      const token = tokenData.data.token;

      // Inclui o token como um parâmetro de consulta na URL
      const link = `/signup?token=${token}`;

      // Processo de envio de email 
      const emailData = {
        type: "Register",
        name: namePersonal,
        email: email,
        replyTo: process.env.REACT_APP_EMAIL,
        link,
        subject: process.env.REACT_APP_EMAIL_SUBJECT,
        remName: process.env.REACT_APP_COPYRIGHT,
        remEmail: process.env.REACT_APP_EMAIL,
      };

      const response = await openApi.post("/send-email", emailData);
      return response;
    } catch (error) {
      console.log("Erro ao enviar o email:", error);      
      // toastError(`Erro ao enviar o email: ${error}`);
    }
  };

  useEffect(() => {
    async function fetchData() {
      // eslint-disable-next-line
      const listAllPublished = await listPlans();
      setPlans(listAllPublished);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // Obtém a URL atual
      const url = new URL(window.location.href);

      // Obtém os parâmetros de consulta
      const params = new URLSearchParams(url.search);

      // Obtém o valor do parâmetro
      const activationCode = params.get("token");

      // Verifica se o valor do parâmetro existe
      if (activationCode && activationCode !== "") {
        const validateData = await openApi.post("/auth/validateTokenSignup", {token: activationCode})
        const { isValid, code } = validateData.data;

        let accountData;
        if (code !== "") {
          accountData = await openApi.get("/leads-web?searchParam=" + code);
        } 
        
        if (!isValid) {
          toast.error("Token de validação da conta expirado.");
          if (accountData && accountData.status === 200) {
            const account = accountData.data.leadsWeb[0];
            setDataResend(account);
            setShowResendModal(true);
          }
        } else {
          if (accountData && accountData.status === 200) {
            const account = accountData.data.leadsWeb[0];

            if (account.status === -1) {
              const msgBaned =
                "Sua conta foi banida da plataforma, entre em contato com suporte técnico.";
              toast.info(msgBaned, {
                autoClose: 5000,
                onClose: () => {
                  if (isInsideIframe) window.location.reload();
                  else history.push("/login");
                }
              });
            }

            if (account.status === 1) {
              const msgJust =
                "Sua conta já foi ativada, faça login para continuar.";
              toast.info(msgJust, {
                autoClose: 5000,
                onClose: () => {
                  history.push("/login");
                }
              });
            }

            if (account.status === 0) {
              const sendAccount = account;
              const dueDate = moment().add(3, "day").format();
              Object.assign(account, { dueDate: dueDate });
              Object.assign(account, { status: true });
              Object.assign(account, { isResale: false });
              Object.assign(account, { password: account.passwordDefault });
              Object.assign(account, { campaignsEnabled: false });
              Object.assign(account, { useApi: false });
              Object.assign(account, { useFacebook: false });
              Object.assign(account, { useInstagram: false });

              try {
                const company = await openApi.post("/companies/cadastro", account);

                if (company && company.status === 200) {
                  Object.assign(sendAccount, { dueDate: dueDate });
                  Object.assign(sendAccount, { activationDate: new Date() });
                  Object.assign(sendAccount, { status: 1 });

                  await openApi.put("/leads-web/" + sendAccount.id, sendAccount);

                  const msgSuccess =
                    "Sua conta foi ativada com sucesso, faça login.";
                  toast.info(msgSuccess, {
                    autoClose: 5000,
                    onClose: () => {
                      history.push("/login");
                    }
                  });
                }
              } catch (err) {
                console.log(err);
                toastError(err);
              }
            }
          }
        }
      } else {
        const originData = params.get("register");
        // console.log("originData: ", originData);
        // console.log("location: ", location);
        if (originData && originData === "front-web-page") {
          const userData = Object.fromEntries(URLSearchParams.entries());
          // console.log("userData:", userData);
          const plano = plans.find(plano => plano.name === userData.planId);
          if (plano) {
            // console.log("Plano selecionado:", plano);
            Object.assign(userData, { planId: plano.id });
          }
          await handleSignUp(userData);
        }
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleMessage = event => {
      if (window.location.origin !== process.env.REACT_APP_FRONTEND_URL) return;

      const messageData = event.data;
      if (messageData.key === "fromIframe" && messageData.message !== "") {
        toast.info("Plano selecionado: " + messageData.message);

        const plano = plans.find(plano => plano.name === messageData.message);
        if (plano) {
          // console.log("Plano selecionado:", plano);
          Object.assign(user, { planId: plano.id });
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [plans, user]);

  function validateVat(vat) {
    if (!vat || typeof vat !== "string") return false;

    const cleanVat = vat.replace(/\D/g, "");
    if (cleanVat.length !== 14) return false;

    if (
      cleanVat === "00000000000" ||
      cleanVat === "11111111111" ||
      cleanVat === "22222222222" ||
      cleanVat === "33333333333" ||
      cleanVat === "44444444444" ||
      cleanVat === "55555555555" ||
      cleanVat === "66666666666" ||
      cleanVat === "77777777777" ||
      cleanVat === "88888888888" ||
      cleanVat === "99999999999"
    ) {
      return false;
    }

    if (cleanVat.length === 14 && !cnpj.isValid(cleanVat)) return false;

    return true;
  }

  const formatValue = value => {
    const cleanedValue = value.replace(/\D/g, "");

    if (cleanedValue.length > 1) {
      if (cleanedValue.length <= 2) {
        return cleanedValue.replace(/(\d{1,2})/, "$1");
      } else if (cleanedValue.length <= 5) {
        return cleanedValue.replace(/(\d{2})(\d{1,3})/, "$1.$2");
      } else if (cleanedValue.length <= 8) {
        return cleanedValue.replace(/(\d{2})(\d{1,3})(\d{1,3})/, "$1.$2.$3");
      } else if (cleanedValue.length <= 12) {
        return cleanedValue.replace(
          /(\d{2})(\d{1,3})(\d{1,3})(\d{1,4})/,
          "$1.$2.$3/$4"
        );
      } else {
        return cleanedValue.replace(
          /(\d{2})(\d{1,3})(\d{1,3})(\d{1,4})(\d{1,2})/,
          "$1.$2.$3/$4-$5"
        );
      }
    } else {
      return cleanedValue;
    }
  };

  const handlePlanSelection = (value, formik) => {
    formik.setFieldValue("planId", value);
  };

  const handleSaveLinkFull = async (link, email) => { 
    if (!link || !email)
      return;
    try {     
      let leadData = await openApi.get("/leads-web?searchParam=" + email);  
      if (leadData && leadData.data.count === 1 && leadData.data.leadsWeb[0].id) {
        const leadPut = leadData.data.leadsWeb[0];
        Object.assign(leadPut, { linkFull: link }); 
        await openApi.put("/leads-web/" + leadPut.id, leadPut);
      }
    } catch (error) {
      toastError(
        `Erro ao salvar o link enviado, tente novamente mais tarde. ${error}`
      );
    } 
  };       

  const handleResendCode = async () => {
    try {
      const sendedEmail = await sendActivationEmail(
        dataResend.name,
        dataResend.namePersonal,
        dataResend.email
      );

      if (
        sendedEmail &&
        sendedEmail?.status === 200 &&
        sendedEmail?.data.result === true
      ) {
        await handleSaveLinkFull(sendedEmail.data.linkFull, dataResend.email);

        toast.info(i18n.t("signup.toasts.success"), {
          autoClose: 5000,
          onClose: () => {
            if (isInsideIframe) window.location.reload();
            else history.push("/login");
          }
        });
      } else toastError(sendedEmail.data.message);
    } catch (error) {
      toastError(
        `Erro ao enviar o email com código, tente novamente mais tarde. ${error}`
      );
    }
    setShowResendModal(false);
  };

  return (
    <>
      <ConfirmationModal
        title="Token expirado"
        open={showResendModal}
        onClose={() => setShowResendModal(false)}
        onConfirm={() => handleResendCode()}
      >
        O token de confirmação da conta expirou. Deseja um novo link de
        confirmação?
      </ConfirmationModal>

      <div className={classes.containerWithScroll}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            {!isInsideIframe && (
              <img
                src={logo}
                width="300"
                height="100"
                alt="logo"
                onContextMenu={e => e.preventDefault()} // Impede o menu de contexto (botão direito)
                draggable="false" // Impede que a imagem seja arrastada
              />
            )}
            <Formik
              initialValues={user}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  handleSignUp(values);
                  actions.setSubmitting(false);
                }, 400);
              }}
            >
              {({ touched, errors, isSubmitting, ...formik }) => (
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        autoComplete="cnpj"
                        fullWidth
                        id="vat"
                        label="CNPJ da Empresa"
                        name="vat"
                        error={touched.vat && Boolean(errors.vat)}
                        helperText={touched.vat && errors.vat}
                        variant="outlined"
                        margin="dense"
                        required
                        inputMode="numeric"
                        pattern="[0-9]*"
                        InputProps={{
                          maxLength: 18
                        }}
                        className={classes.field}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        autoComplete="name"
                        name="name"
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        id="name"
                        label="Nome da Empresa"
                        className={classes.field}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        autoComplete="phone"
                        name="phone"
                        error={touched.phone && Boolean(errors.phone)}
                        helperText={touched.phone && errors.phone}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        id="phone"
                        label="WhatsApp da Empresa"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        InputProps={{
                          maxLength: 20
                        }}
                        className={classes.field}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        autoComplete="name"
                        name="namePersonal"
                        error={
                          touched.namePersonal && Boolean(errors.namePersonal)
                        }
                        helperText={touched.namePersonal && errors.namePersonal}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        id="namePersonal"
                        label="Seu nome"
                        className={classes.field}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        id="email"
                        label={`${i18n.t(
                          "signup.form.email"
                        )} (Login de acesso)`}
                        name="email"
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        autoComplete="email"
                        required
                        className={classes.field}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        name="password"
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        label={i18n.t("signup.form.password")}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        required
                        className={classes.field}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="planId">
                        {({ field }) => (
                          <>
                            {!isInsideIframe && (
                              <>
                                <InputLabel htmlFor="plan-selection">
                                  {i18n.t("signup.form.plan")}
                                </InputLabel>
                                <Select
                                  {...field}
                                  variant="outlined"
                                  margin="dense"
                                  fullWidth
                                  id="plan-selection"
                                  label="Plano de assinatura (Atendentes)"
                                  required
                                  className={classes.field}
                                  onChange={event =>
                                    handlePlanSelection(
                                      event.target.value,
                                      formik
                                    )
                                  }
                                >
                                  {plans.map((plan, key) => (
                                    <MenuItem key={key} value={plan.id}>
                                      {plan.name} - Atendentes: {plan.users} -
                                      WhatsApp: {plan.connections} - Filas:{" "}
                                      {plan.queues} - {plan.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </>
                            )}
                          </>
                        )}
                      </Field>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: '10px' }}> 
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={termsAccepted}
                            onChange={handleTermsChange}
                            name="termsAccepted"
                            color="primary"
                          />
                        }
                        label={
                          <span style={{ display: 'flex', alignItems: 'center' }}>
                            Aceito os&nbsp; 
                            <MUILink
                              href={`${process.env.REACT_APP_LANDING_PAGE_URL}/page-terms.html`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ whiteSpace: 'nowrap' }}
                            > 
                              termos de uso 
                            </MUILink>
                            &nbsp;e&nbsp;
                            <MUILink
                              href={`${process.env.REACT_APP_LANDING_PAGE_URL}/privace-policy.html`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ whiteSpace: 'nowrap' }}
                            > 
                              política de privacidade 
                            </MUILink>
                          </span>
                        }
                      /> 
                  </Grid>

                  <Grid container className={classes.submitContainer}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={!termsAccepted}
                      className={`${classes.submit} ${
                        isInsideIframe ? "" : classes.primary
                      }`}
                    >
                      {i18n.t("signup.buttons.submit")}
                    </Button>
                  </Grid>
                  {!isInsideIframe && (
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        <Link
                          href="#"
                          variant="body2"
                          component={RouterLink}
                          to="/login"
                        >
                          {i18n.t("signup.buttons.login")}
                        </Link>
                      </Grid>
                    </Grid>
                  )}
                </Form>
              )}
            </Formik>
          </div>
          {!isInsideIframe && (
            <Box mt={5}>
              <Copyright />
            </Box>
          )}
        </Container>
      </div>
    </>
  );
};

export default SignUp;
