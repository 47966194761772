import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useRef
} from "react";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import {
  Checkbox,
  Menu,
  MenuItem,
  TableBody,
  TableFooter
} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactModal from "../../components/ContactModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import NewTicketModal from "../../components/NewTicketModal";
import { socketConnection } from "../../services/socket";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import {
  ArrowDropDown,
  Backup,
  CloudDownload,
  ContactPhone
} from "@material-ui/icons";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import ImageModal from "../../components/ImageModal";
import ContactListSelectModal from "../../components/ContactListSelectModal";
import useCampaigns from "../../hooks/useCampaigns";
import { IconChannel } from "../../helpers/perso";
import formatSerializedId from "../../helpers/formatSerializedId";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach(contact => {
      const contactIndex = state.findIndex(c => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex(c => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    // const contactId = action.payload;

    // const contactIndex = state.findIndex(c => c.id === contactId);
    // if (contactIndex !== -1) {
    //   state.splice(contactIndex, 1);
    // }
    // return [...state];

    const contactIds = Array.isArray(action.payload)
      ? action.payload
      : [action.payload];

    // Filtrar os contatos que não estão no array de IDs para serem excluídos
    const newState = state.filter(contact => !contactIds.includes(contact.id));

    return [...newState];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles
  }
}));

const Contacts = () => {
  const classes = useStyles();
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [contactTicket, setContactTicket] = useState({});
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const fileUploadRef = useRef(null);
  const [toSelect, setToSelect] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [contactListSelectModal, setContactListSelectModal] = useState(false);

  const [openAvatarModal, setOpenAvatarModal] = useState(false);
  const [selectedImageAvatarModal, setSelectedImageAvatarModal] = useState("");
  const [showCampaigns, setShowCampaigns] = useState(false);
  const { findPermissions } = useCampaigns();

  useEffect(() => {
    if (localStorage.getItem("cshow")) {
      setShowCampaigns(true);
    }
  }, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam, pageNumber }
          });
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-contact`, data => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_CONTACT",
          payload:
            data.contactIds && Array.isArray(data.contactIds)
              ? data.contactIds
              : +data.contactId
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = event => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenContactModal = () => {
    //não deixa a caixa de seleção ativa para adicionar contato
    setToSelect(false);
    setSelectedContacts([]);

    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
    setContactListSelectModal(false);
  };

  const handleCloseOrOpenTicket = ticket => {
    //não deixa a caixa de seleção ativa para acessar o ticket
    setToSelect(false);
    setSelectedContacts([]);

    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const handleEditContact = contactId => {
    //não deixa a caixa de seleção ativa para editar contato
    setToSelect(false);
    setSelectedContacts([]);

    setContactListSelectModal(false);
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleDeleteContact = async contactIds => {
    try {
      if (Array.isArray(contactIds) && contactIds.length > 1) {
        await api.delete(`/contacts`, { data: { contactIds } });
        toast.success(i18n.t("contacts.toasts.deletedMultiple"));
      } else {
        let contactId;
        if (Array.isArray(contactIds) && contactIds.length === 1) {
          contactId = contactIds[0];
        } else {
          contactId = contactIds;
        }
        await api.delete(`/contacts/${contactId}`);
        toast.success(i18n.t("contacts.toasts.deleted"));
      }
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);

    setToSelect(false);
    setAllSelected(false);
    setSelectedContacts([]);
  };

  const handleToSelect = () => {
    setToSelect(!toSelect);

    if (!toSelect) {
      setAllSelected(false);
      setContactListSelectModal(false);
      setSelectedContacts([]);
    }
  };

  const handleSelectContact = id => {
    setSelectedContacts(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(contactId => contactId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedContacts([]);
    } else {
      setSelectedContacts(contacts.map(contact => contact.id));
    }
    setAllSelected(!allSelected);
  };

  const handleImportContactPhone = async () => {
    try {
      await api.post("/contacts/import/phone");
      history.go(0);
    } catch (err) {
      toastError(err);
    }
  };

  const handleImportContactExcel = async () => {
    try {
      const formData = new FormData();
      formData.append("file", fileUploadRef.current.files[0]);
      await api.request({
        url: `/contacts/import/excel`,
        method: "POST",
        data: formData
      });
      history.go(0);
    } catch (err) {
      toastError(err);
    }
  };

  const handleExportExcel = async () => {
    try {
      const response = await api.post(
        `/contacts/export/excel`,
        {
          contactIds: selectedContacts
        },
        {
          responseType: "blob"
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "contacts.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      toastError(err);
    }
  };

  const handleExportSelected = async () => {
    if (selectedContacts.length === 0) {
      toast.warn("Nenhum contato foi selecionado!");
      return;
    }

    await handleExportExcel();

    setToSelect(false);
    setAllSelected(false);
    setSelectedContacts([]);
  };

  const loadMore = () => {
    setPageNumber(prevState => prevState + 1);
  };

  const handleScroll = e => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleOpenAvatarModal = imageUrl => {
    setSelectedImageAvatarModal(imageUrl);
    setOpenAvatarModal(true);
  };

  const handleCloseAvatarModal = () => {
    setOpenAvatarModal(false);
    setSelectedImageAvatarModal("");
  };

  function keepFromStart(arr, x) {
    const itemsToKeep = Math.min(x, arr.length);
    if (itemsToKeep < arr.length) {
      arr.splice(itemsToKeep);
    }
    return arr;
  }

  const handleAddToListCampaigns = async listId => {
    if (!listId || selectedContacts.length === 0) return;

    const data = await findPermissions();
    const group = data.stats.contactsInGroupsCampaigns.find(
      group => Number(group.id) === Number(listId)
    );
    const value = group ? group.value : 0;
    if (value === 0) {
      toast.warn(
        "Você atingiu o limite de números de contatos nesta lista de contatos no seu plano!"
      );
      return;
    }

    toast.warn(
      `Esta lista de contatos ainda tem um limite de ${value} contatos!`
    );
    const selectedContactsFilter = keepFromStart(selectedContacts, value);

    try {
      const response = await api.post(`/contacts/transferToListCampaigns`, {
        contactIds: selectedContactsFilter,
        contactListId: listId
      });

      if (response.status === 200) {
        toast.info("Contatos adicionados com sucesso!");
      }
    } catch (err) {
      toastError(err);
    } finally {
      setToSelect(false);
      setAllSelected(false);
      setSelectedContacts([]);

      setSelectedContactId(null);
      setContactModalOpen(false);
      setContactListSelectModal(false);
    }
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        initialContact={contactTicket}
        isReopen={false}
        onClose={ticket => {
          handleCloseOrOpenTicket(ticket);
        }}
      />
      <ImageModal
        src={selectedImageAvatarModal}
        alt=""
        open={openAvatarModal}
        onClose={handleCloseAvatarModal}
      />
      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      />
      <ContactListSelectModal
        open={contactListSelectModal}
        onClose={() => setContactListSelectModal(false)}
        onSelect={listId => handleAddToListCampaigns(listId)}
      />
      <ConfirmationModal
        title={
          deletingContact || (toSelect && selectedContacts.length > 0)
            ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${
                deletingContact?.name
                  ? deletingContact?.name
                  : selectedContacts.length.toString() + " contato(s)"
              }?`
            : `${i18n.t("contacts.confirmationModal.importTitlte")}`
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={e =>
          deletingContact || (toSelect && selectedContacts.length > 0)
            ? handleDeleteContact(
                toSelect && selectedContacts.length > 0
                  ? selectedContacts
                  : deletingContact.id
              )
            : handleImportContactPhone()
        }
      >
        {deletingContact || (toSelect && selectedContacts.length > 0)
          ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
          : `${i18n.t("contacts.confirmationModal.importMessage")}`}
      </ConfirmationModal>
      <MainHeader>
        <Title>{i18n.t("contacts.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              )
            }}
          />

          <Can
            role={user.profile}
            perform="contacts-page:importExportContact"
            yes={() => (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleToSelect();
                  }}
                >
                  {i18n.t("contacts.buttons.select")}
                </Button>
                {toSelect && selectedContacts.length > 0 && (
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {popupState => (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          color="primary"
                          {...bindTrigger(popupState)}
                        >
                          {`${i18n.t(
                            "contacts.buttons.selecteds"
                          )} [${selectedContacts.length.toString()}]`}
                          <ArrowDropDown />
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem
                            onClick={() => {
                              setDeletingContact(null);
                              setConfirmOpen(true);
                              popupState.close();
                            }}
                          >
                            <DeleteSweepIcon
                              fontSize="small"
                              color="primary"
                              style={{ marginRight: 10 }}
                            />
                            {`${i18n.t(
                              "contacts.buttons.deleteSelected"
                            )} [${selectedContacts.length.toString()}]`}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleExportSelected();
                              popupState.close();
                            }}
                          >
                            <CloudDownload
                              fontSize="small"
                              color="primary"
                              style={{ marginRight: 10 }}
                            />
                            {`${i18n.t(
                              "contacts.buttons.exportSelectedExcel"
                            )} [${selectedContacts.length.toString()}]`}
                          </MenuItem>
                          {showCampaigns && (
                            <MenuItem
                              onClick={() => {
                                setContactListSelectModal(true);
                                popupState.close();
                              }}
                            >
                              <MoveToInboxIcon
                                fontSize="small"
                                color="primary"
                                style={{ marginRight: 10 }}
                              />
                              {`${i18n.t(
                                "contacts.buttons.addToListCampaigns"
                              )} [${selectedContacts.length.toString()}]`}
                            </MenuItem>
                          )}
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                )}
                {(!toSelect || selectedContacts.length === 0) && (
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {popupState => (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          color="primary"
                          {...bindTrigger(popupState)}
                        >
                          {i18n.t("contacts.buttons.title")}
                          <ArrowDropDown />
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem
                            onClick={() => {
                              //não deixa a caixa de seleção ativa para importar
                              setToSelect(false);
                              setSelectedContacts([]);

                              setConfirmOpen(true);
                              popupState.close();
                            }}
                          >
                            <ContactPhone
                              fontSize="small"
                              color="primary"
                              style={{
                                marginRight: 10
                              }}
                            />
                            {i18n.t("contacts.buttons.importPhone")}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              //não deixa a caixa de seleção ativa para importar
                              setToSelect(false);
                              setSelectedContacts([]);

                              fileUploadRef.current.value = null;
                              fileUploadRef.current.click();
                              popupState.close();
                            }}
                          >
                            <Backup
                              fontSize="small"
                              color="primary"
                              style={{
                                marginRight: 10
                              }}
                            />
                            {i18n.t("contacts.buttons.importExcel")}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              //não deixa a caixa de seleção ativa para exportar todos
                              setToSelect(false);
                              setSelectedContacts([]);

                              handleExportExcel();
                              popupState.close();
                            }}
                          >
                            <CloudDownload
                              fontSize="small"
                              color="primary"
                              style={{ marginRight: 10 }}
                            />
                            {i18n.t("contacts.buttons.exportExcel")}
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                )}
              </>
            )}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenContactModal}
          >
            {i18n.t("contacts.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <>
          <input
            style={{ display: "none" }}
            id="upload"
            name="file"
            type="file"
            accept=".csv,.xls,.xlsx"
            onChange={handleImportContactExcel}
            ref={fileUploadRef}
          />
        </>
        <Table size="small">
          <TableHead>
            <TableRow>
              {!toSelect && <TableCell padding="checkbox" />}
              <TableCell
                style={{ paddingRight: 0 }}
                padding="checkbox"
                align="left"
              >
                {toSelect && (
                  <Checkbox checked={allSelected} onChange={handleSelectAll} />
                )}
              </TableCell>
              {toSelect && <TableCell align="center" />}
              <TableCell>{i18n.t("contacts.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.whatsapp")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.email")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contacts.map(contact => (
                <TableRow key={contact.id}>
                  <TableCell style={{ paddingRight: 0 }}>
                    {toSelect && (
                      <Checkbox
                        checked={selectedContacts.includes(contact.id)}
                        onChange={() => handleSelectContact(contact.id)}
                      />
                    )}
                  </TableCell>
                  <TableCell style={{ paddingRight: 0 }}>
                    <Avatar
                      src={contact.profilePicUrl}
                      onClick={() =>
                        !contact.profilePicUrl.includes("nopicture")
                          ? handleOpenAvatarModal(contact.profilePicUrl)
                          : null
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </TableCell>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell align="center">
                    {contact.isGroup
                      ? contact.number
                      : formatSerializedId(contact?.number) === null
                      ? contact.number.slice(0, -6) +
                        "**-**" +
                        contact?.number.slice(-2)
                      : formatSerializedId(contact?.number)}
                  </TableCell>
                  <TableCell align="center">{contact.email}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setContactTicket(contact);
                        setNewTicketModalOpen(true);
                      }}
                    >
                      {IconChannel(contact.channel)}
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleEditContact(contact.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <Can
                      role={user.profile}
                      perform="contacts-page:deleteContact"
                      yes={() => (
                        <IconButton
                          size="small"
                          onClick={() => {
                            //não deixa a caixa de seleção ativa para excluir um contato
                            setToSelect(false);
                            setSelectedContacts([]);

                            setConfirmOpen(true);
                            setDeletingContact(contact);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton avatar columns={3} />}
            </>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="left">
                {i18n.t("table.totalRecords") +
                  (contacts ? contacts?.length : 0)}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Contacts;
