import React from "react";
import { Button, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
    cursor: "pointer",
    fontSize: "0.8rem",
    marginRight: 0,
    marginLeft: 2,
    display: "inline-block",
  },
  button: {
    margin: theme.spacing(0.5),
    cursor: "pointer",
    fontSize: "0.65rem", // Reduzir o tamanho da fonte
    textTransform: "none",
    padding: "2px 4px", // Reduzir o padding para botões menores
    borderRadius: "8px", // Reduzir o borderRadius para bordas menos arredondadas
    minWidth: "auto",
    color: "#555", // Cor do texto cinza
    backgroundColor: "#f0f0f0", // Cor de fundo cinza claro
    border: "1px solid #d0d0d0", // Borda cinza clara
    "&:hover": {
      backgroundColor: "#e0e0e0", // Cor de fundo ao passar o mouse
    },
  },
}));

const HelperTextWithChips = ({ onChipClick }) => {
  const classes = useStyles();

  const chipOptions = [
    {
      name: "Spintax saudação",
      value: "{Olá|Oi|Opa|Opa jóia?|Olá, tudo bem?|Oi, tudo jóia?} ",
      hint: "É possível usar o spintax, ex.: {Olá|Oi|Hello}"
    },
    {
      name: "Nome contato",
      value: "{{name}} ",
      hint: "Nome do contato do WhatsApp ou que esta nos contatos"
    },
    {
      name: "Email contato",
      value: "{{email}} ",
      hint: "Email do contato que esta na agenda da plataforma"
    },
    {
      name: "Bom dia, boa tarde...",
      value: "{{ms}} ",
      hint: "Dependendo do horário da mensagem"
    },
    {
      name: "Nº. protocolo",
      value: "{{protocol}} ",
      hint: "Útil na mensagem inicial ou de conclusão do ticket"
    },
    {
      name: "Data",
      value: "{{{date}}} ",
      hint: "Data atual"
    },
    {
      name: "Hora",
      value: "{{hour}} ",
      hint: "Hora atual"
    },
    {
      name: "Empresa",
      value: localStorage.getItem("companyName") + ' ',
      hint: "Nome da empresa"
    },
    {
      name: "CNPJ",
      value: localStorage.getItem("companyVat") + ' ',
      hint: "CNPJ da empresa"
    },
    {
      name: "Telefone",
      value: localStorage.getItem("companyPhone") + ' ',
      hint: "Telefone/WhatsApp da empresa"
    },
    {
      name: "Email",
      value: localStorage.getItem("companyEmail") + ' ',
      hint: "Email da empresa"
    },
  ];
  
  const handleClick = value => {
    onChipClick(value);
  };

  // const chips = chipOptions.map((msgVar, index) => (
  //   <Tooltip key={msgVar.value} title={msgVar.hint}>
  //     <Chip
  //       className={classes.chip}
  //       onMouseDown={() => handleClick(msgVar.value)}
  //       label={msgVar.name}
  //       size="small"
  //     />
  //   </Tooltip>
  // ));

  // return chips;
 
  return (
    <>
      {chipOptions.map((msgVar, index) => (
        <Tooltip key={msgVar.value} title={msgVar.hint}>
          <Button
            className={classes.button}
            onMouseDown={() => handleClick(msgVar.value)}
          >
            {msgVar.name}
          </Button>
        </Tooltip>
      ))}
    </>
  );  
};

export default HelperTextWithChips;
