import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import api from "../../services/api";

const ForwardMessageModal = ({ modalOpen, onClose, messages }) => {
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!modalOpen) return;
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTickets = async () => {
        try {
          const queueIds = user?.queues.map(queue => queue.id); 
          const { data } = await api.get("/tickets", {
            params: {
              searchParam,
              status: "open",
              showAll: user.profile === "admin" ? true : false,
              users: user.profile === "admin" ? [] : [user.id],
              queueIds: user.profile === "admin" ? JSON.stringify(queueIds) : [],
            }
          });
          let ticketsData = data.tickets;
          if (user.profile !== "admin" && ticketsData.length > 0) {
            ticketsData = data.tickets.filter(ticket => ticket.userId === user.id);
          }
          setTickets(ticketsData);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchTickets();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, modalOpen, user.profile, user.id, user?.queues]);

  useEffect(() => {
    if (tickets) { 
      const data = tickets.map(ticket => {
        return {
          id: ticket.contact.id,
          name: ticket.contact.name,
          number: ticket.contact.number
        };
      });
      setOptions(data);
    }
    setLoading(false);
  }, [tickets]);

  const handleClose = () => {
    onClose();
    setSearchParam("");
    setSelectedContact(null);
  };

  const handleSelectOption = (e, newValue) => {
    if (newValue?.number) {
      setSelectedContact(newValue);
    }
  };

  const renderOption = option => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    }
  };

  const renderOptionLabel = option => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    } else {
      return `${option.name}`;
    }
  };

  const renderContactAutocomplete = () => {
    return (
      <Grid xs={12} item>
        <Autocomplete
          fullWidth
          options={options}
          loading={loading}
          clearOnBlur
          autoHighlight
          freeSolo
          clearOnEscape
          getOptionLabel={renderOptionLabel}
          renderOption={renderOption}
          onChange={(e, newValue) => handleSelectOption(e, newValue)}
          renderInput={params => (
            <TextField
              {...params}
              label={"Digite para pesquisar o ticket"}
              variant="outlined"
              autoFocus
              onChange={e => setSearchParam(e.target.value)}
              onKeyPress={e => {
                if (loading || !selectedContact) return;
                else if (e.key === "Enter") {
                  // handleSaveTicket(selectedContact.id);
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />
      </Grid>
    );
  };

  const handleForwardMessage = async contactId => {
    if (!contactId) {
      toast.error("Selecione um atendimento");
      return;
    }

    const messageIds = messages.map(message => message.id);
    try {
      setLoading(true);
      const { data } = await api.post("/message/forward", {
        messageId: messageIds,
        contactId
      });

      if (data.status) {
        toast.info(data.message);
        history.push(`/tickets/${data.uuid}`);
      }
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <>
      <Dialog open={modalOpen} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">{"Encaminhar"}</DialogTitle>
        <DialogContent dividers>
          <Grid style={{ width: 300 }} container spacing={2}>
            {renderContactAutocomplete()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            disabled={loading}
            variant="outlined"
          >
            {i18n.t("newTicketModal.buttons.cancel")}
          </Button>
          <ButtonWithSpinner
            variant="contained"
            type="button"
            disabled={!selectedContact}
            onClick={() => handleForwardMessage(selectedContact.id)}
            color={"primary"}
            loading={loading}
          >
            {"Encaminhar"}
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ForwardMessageModal;
