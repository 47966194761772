import React, { useContext, useEffect, useState } from "react";
import {
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
} from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useDate } from "../../hooks/useDate";
import whatsBackground from "../../assets/wa-background.png";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ConfirmationModal from "../../components/ConfirmationModal";
import api from "../../services/api";
import { ListItemTextContainer, ListItemTitle } from "./styles"; 
import { formatTextWithLimit } from "../../helpers/perso";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    //height: "calc(100% - 58px)",
    overflow: "hidden",
    borderRadius: 0,
    backgroundColor: "white",
  },
  chatList: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  listItem: {
    cursor: "pointer",
  },
}));

export default function ChatList({
  chats,
  handleSelectChat,
  handleDeleteChat,
  handleEditChat,
  pageInfo,
  loading,
  setIsShowInformation,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { datetimeToClient } = useDate();
  const [confirmationModal, setConfirmModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState({});

  const { id } = useParams();

  const goToMessages = async (chat) => {
    // Loading.turnOn();
    if (unreadMessages(chat) > 0) {
      try {
        try {
          await api.post(`/chats/${chat.id}/read`, { userId: user.id });
        } catch (err) {}
      } catch (err) {}
    }
    
    if (id !== chat.uuid) {
      history.push(`/chats/${chat.uuid}`);
      handleSelectChat(chat);
    }

    if (setIsShowInformation) setIsShowInformation(true);
    setTimeout(() => {
      // Loading.turnOff();
    }, [2000]);
  };

  const handleDelete = () => {
    handleDeleteChat(selectedChat);
  };

  const unreadMessages = (chat) => {
    const currentUser = chat.users.find((u) => u.userId === user.id);
    return currentUser?.unreads;
  };

  const getPrimaryText = (chat) => {
    const mainText = chat.title;
    const unreads = unreadMessages(chat);
    return (
      <>
        <ListItemTitle>
          <GroupIcon />
          <div>{mainText}</div>
          {unreads > 0 && (
            <Chip
              size="small"
              style={{ marginLeft: 5 }}
              label={unreads}
              color="secondary"
            />
          )}
        </ListItemTitle>
        <div style={{ fontSize: "12px" }}>
          {datetimeToClient(chat.updatedAt)}
        </div>
      </>
    );
  };

  const getSecondaryText = (chat) => {
    return chat.lastMessage !== ""
      ? `${formatTextWithLimit(chat.lastMessage, 40)}`
      : "";
  };

  const getItemStyle = (chat) => {
    return {
      borderLeft: chat.uuid === id ? "6px solid #006B76" : null,
      backgroundColor: chat.uuid === id ? "#eee" : null,
    };
  };

  return (
    <>
      {!!confirmationModal && (
        <ConfirmationModal
          title={`Deseja realmente excluir o chat interno "${selectedChat?.title}"?`}
          open={confirmationModal}
          onClose={setConfirmModalOpen}
          onConfirm={handleDelete}
        >
          Esta ação não pode ser revertida, confirmar?
        </ConfirmationModal>
      )}
      <div
        className={classes.mainContainer}
        //style={{ backgroundImage: `url(${whatsBackground})` }}
      >
        <div className={classes.chatList}>
          <List component="paper">
            {!!Array.isArray(chats) &&
              !!chats.length > 0 &&
              chats.map((chat, key) => (
                <Paper elevation={3}>
                  <ListItem
                    onClick={() => goToMessages(chat)}
                    key={key}
                    className={classes.listItem}
                    style={getItemStyle(chat)}
                    button
                  >
                    <ListItemTextContainer>
                      <ListItemText
                        primary={getPrimaryText(chat)}
                        secondary={getSecondaryText(chat)}
                      />
                    </ListItemTextContainer>
                    {chat.ownerId === user.id && (
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => {
                            goToMessages(chat).then(() => {
                              handleEditChat(chat);
                            });
                          }}
                          edge="end"
                          aria-label="delete"
                          size="small"
                          color="primary"
                          style={{ marginRight: 5 }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setSelectedChat(chat);
                            setConfirmModalOpen(true);
                          }}
                          edge="end"
                          aria-label="delete"
                          color="secondary"
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </Paper>
              ))}
          </List>
        </div>
      </div>
    </>
  );
}
