import { formatMask } from './formatMask'; 

const formatSerializedId = (serializedId) => {
  const format = new formatMask();
  const number = serializedId?.replace('@c.us', '');

  return format.setPhoneFormatMask(number); //?.replace('+55', '🇧🇷');
};

export default formatSerializedId;
