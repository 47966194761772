import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select"; 

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const ItemSelect = ({ title, selectedValues, onChange, items, minWidth = 200, labelWidth = 45, disabled = false }) => {
  // eslint-disable-next-line
  const classes = useStyles();
  const [selectedItemValues, setSelectedItemValues] = useState(
    (items && items.length > 0) ? selectedValues || items[0].value || [] : []
  );

  useEffect(() => {
    if (items && items.length > 0) {
      setSelectedItemValues(selectedValues || items[0].value || []);
    }
  }, [selectedValues, items]);

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedItemValues(Array.isArray(value) ? value : [value]);
    if (typeof onChange === "function") {
      onChange(value);
    }
  };
 
  return (
    <div> 
      <FormControl
        variant="outlined"
        margin="dense"
        fullWidth
        style={{ marginRight: 5, minWidth: minWidth }}  
      >      
        <InputLabel shrink>{title}</InputLabel>
        <Select 
          margin="dense"
          variant="outlined"
          labelWidth={labelWidth} 
          value={selectedItemValues}
          onChange={handleChange} 
          disabled={disabled}
          inputlabelprops={{shrink: true,}}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {(items && items.length > 0) && items.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default ItemSelect;
