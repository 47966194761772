import React, { useEffect } from 'react';
import toastError from "../../errors/toastError";
import { Button, Divider, Typography, useTheme } from "@material-ui/core";

const LocationPreview = ({ image, link, description, isQuotedMsgRender }) => {
    useEffect(() => {}, [image, link, description]);
	const theme = useTheme();

    const handleLocation = async() => {
        try {
            window.open(link);
        } catch (err) {
            toastError(err);
        }
    }

    return (
		<>
			<div style={{
				minWidth: "250px",
			}}>
				<div>
					<div style={{ float: "left" }}>
						<img src={image} alt="loc" onClick={handleLocation} style={{ width: "100px" }} />
					</div>
					{ description && (
					<div style={{ display: "flex", flexWrap: "wrap" }}>
						<Typography 
							style={{ 
								marginTop: "12px", 
								marginLeft: "15px", 
								marginRight: "15px", 
								float: "left" 
							}} 
								variant="subtitle1" 
								color={theme.palette.type === 'dark' ? 'secondary' : 'primary'}
								gutterBottom
							>
							<div dangerouslySetInnerHTML={{ __html: description.replace('\\n', '<br />') }}></div>
						</Typography>
					</div>
					)}
					<div style={{ display: "block", content: "", clear: "both" }}></div>
					{!isQuotedMsgRender && (
						<div>
							<Divider />
							<Button
								fullWidth
								color={theme.palette.type === 'dark' ? 'secondary' : 'primary'}
								variant='outlined'
								onClick={handleLocation}
								disabled={!link}
							>
								Visualizar
							</Button>
						</div>
					)}
				</div>
			</div>
		</>
	);

};

export default LocationPreview;