// eslint-disable-next-line
import React, { useState, useEffect, useReducer } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


import usePlans from "../../../hooks/usePlans";
import useCompanies from "../../../hooks/useCompanies";
import toastError from '../../../errors/toastError';
import ItemSelect from '../../ItemSelect';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  margin: {
    margin: theme.spacing(1),
  },


  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
      padding: theme.spacing(2), // Exemplo: ajuste de preenchimento
      marginBottom: theme.spacing(1), // Exemplo: ajuste de margem inferior      
  },
  cardHeaderTitle: {
    fontSize: '1.5rem', // Ajuste o tamanho da fonte do título conforme necessário
  },  
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  cardPricingValue: {
    fontSize: '2rem', // Ajuste o tamanho da fonte do valor conforme necessário
  },  
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },

  customCard: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    flexDirection: "column",
  },
  custom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
}));


export default function Pricing(props) {
  const {
    setFieldValue,
    setActiveStep,
    activeStep,
    // eslint-disable-next-line
    invoiceId,
    invoice,
    mode
  } = props;
 
  const { listAllPublished, finder } = usePlans();
  const { find } = useCompanies();
  
  const classes = useStyles();
  // eslint-disable-next-line
  const [usersPlans, setUsersPlans] = React.useState(3); 
  // eslint-disable-next-line
  const [companyPlanId, setCompanyPlanId] = useState(0); 
  // eslint-disable-next-line
  const [recurrence, setRecurrence] = useState('');
  // eslint-disable-next-line
  const [connectionsPlans, setConnectionsPlans] = React.useState(3);
  const [storagePlans, setStoragePlans] = React.useState([]);
  const [plansUpgrade, setPlansUpgrade] = React.useState([]);
  const [planSelected, setPlanSelected] = React.useState(null);
  // eslint-disable-next-line
  const [customValuePlans, setCustomValuePlans] = React.useState(49.00);
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  const [allowNext, setAllowNext] = React.useState(false);
  const companyId = mode ? localStorage.getItem("companyId") : invoice.companyId; 
 
  const itemsTypeAssign = [
    { value: 'MENSAL', name: "Mensal"},
    // { value: 'BIMESTRAL', name: "Bimestral"},
    // { value: 'TRIMESTRAL', name: "Trimestral"},
    // { value: 'SEMESTRAL', name: "Semestral"},
    { value: 'ANUAL', name: "Anual"},
  ];

  useEffect(() => {
    async function fetchData() {
      await loadCompanies();
    }
    fetchData();
  // eslint-disable-next-line
  }, [])

  const loadCompanies = async () => {
    setLoading(true);
    try {
      const companiesList = await find(companyId); 
      const lRecurrence = companiesList.recurrence;
      const lPlanId = companiesList.planId;
      setRecurrence(lRecurrence); 
      setCompanyPlanId(lPlanId);
       
      if (mode) {
        const plansData = await listAllPublished();
        const plansFilter = plansData.filter(
            (plan) => plan.id !== lPlanId).map((plan) => ({ value: plan.id, name: plan.name })
          );
        setPlansUpgrade(plansFilter);
        // console.log('plansFilter:', JSON.stringify(plansFilter)); 
        // console.log('plansUpgrade:', plansUpgrade); 
        // console.log('plansFilter[0].value:', plansFilter[0].value);         
        // console.log('lRecurrence:', lRecurrence); 
        // setRecurrence(companiesList.recurrence);
        setPlanSelected(plansFilter[0].value);
        await loadPlans(plansFilter[0].value, lRecurrence, lPlanId);
      } else
        await loadPlans(lPlanId, lRecurrence);
    } catch (e) {
      console.log(e);
      // toast.error("Não foi possível carregar a lista de registros");
    }
    setLoading(false);
  };

  const loadPlans = async (companyPlanDataId, recurrencePlan, currentPlanId = null) => {
    setLoading(true);
    try { 
      let description = [];
      let plans = [];
      let isPublishedCurrentPlan = true;
      const planCompany = await finder(companyPlanDataId); 
      
      // Verificando se o plano atual foi passado e se o mesmo é personalizado
      if (mode && currentPlanId) {
        const currentPlanTarget = await finder(currentPlanId);
        if (currentPlanTarget)
          isPublishedCurrentPlan = currentPlanTarget.isPublished;
      }

      // console.log('planCompany:', JSON.stringify(planCompany));  
      if (!recurrence && recurrencePlan) {
        setRecurrence(recurrencePlan); 
      }

      if (mode === true) {
        if (!isPublishedCurrentPlan) {
          setAllowNext(true);
          console.log('Plano de assinatura personalizado, não é possível realizar o upgrade!');
          return toastError('Seu plano é personalizado, não é possível realizar o upgrade. Fale com suporte técnico!');
        }

          description = [
            `${planCompany.users} ${planCompany.users > 1 ? 'Usuários' : 'Usuário'}`,
            `${planCompany.connections} ${planCompany.connections > 1 ? 'Conexões' : 'Conexão'}`,
            `${planCompany.queues} ${planCompany.queues > 1 ? 'Filas' : 'Fila'}`,
          ];
  
          plans.push({
            title: planCompany.name,
            planId: planCompany.id,
            price: recurrencePlan.toUpperCase() === itemsTypeAssign[0].value ? planCompany.value : planCompany.valueYearly, 
            recurrence: recurrencePlan, 
            invoiceId: null,  
            companyId: companyId, // added
            useDescription: null, 
            description,
            users: planCompany.users,
            connections: planCompany.connections,
            queues: planCompany.queues,
            buttonText: 'ASSINAR PLANO',
            buttonVariant: 'outlined', 
            mode
          }); 

      } else {  

        description = [
          `${planCompany.users} ${planCompany.users > 1 ? 'Usuários' : 'Usuário'}`,
          `${planCompany.connections} ${planCompany.connections > 1 ? 'Conexões' : 'Conexão'}`,
          `${planCompany.queues} ${planCompany.queues > 1 ? 'Filas' : 'Fila'}`, 
        ];
  
        if (invoice.description && !invoice.detail)
          description = [
            `${invoice.description}`,
          ];
  
        plans.push({
          title: (invoice.description && !invoice.detail) ? 
            `Fatura ${"#"}${new Date().getFullYear()}${"/"}${invoice.id}` : 
            (invoice?.detail !== '' ? invoice?.detail : planCompany.name),
          planId: planCompany.id,
          price: invoice.value, 
          recurrence: recurrencePlan, // added
          invoiceId: invoice.id, // added
          companyId: invoice.companyId, // added
          useDescription: (invoice.description && !invoice.detail), // added
          description,
          users: planCompany.users,
          connections: planCompany.connections,
          queues: planCompany.queues,
          buttonText: 'SELECIONAR',
          buttonVariant: 'outlined', 
          mode
        });          
      }
      // console.log('plans: ', JSON.stringify(plans));
      if (plans.length > 0)
        setStoragePlans(plans);
      
    } catch (e) {
      console.log(e);
      // toast.error("Não foi possível carregar a lista de registros");
    }
    setLoading(false);
  };

  const handleItemSelect = async (selectedValue) => {
    // console.log('Plano selecionado:', selectedValue); 
    setPlanSelected(selectedValue);
    await loadPlans(selectedValue, recurrence); 
  };  
 
  const handleSelectRecorrence = async (selectedValue) => {
    // console.log('Recorrence selecionado:', selectedValue); 
    setRecurrence(selectedValue);
    // console.log('companyPlanId selecionado:', planSelected); 
    await loadPlans(planSelected, selectedValue); 
  };  
 
  return (
    <React.Fragment>
      <Grid container spacing={3} style={{ minWidth: 300 }}>
        {/* <Grid item xs={12} sm={12} md={12}> */}
          {mode && (
            <> 
              {/* <Grid container spacing={7}>             */}
                <Grid item xs={12} sm={6} md={6} style={{ minWidth: 150 }}>
                  <ItemSelect 
                    title={'Selecione o plano'} 
                    items={plansUpgrade} 
                    selectedValues={planSelected === 0 ? undefined : planSelected}
                    onChange={handleItemSelect} 
                    minWidth={170}
                    labelWidth={132}
                    disabled={allowNext} 
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{ minWidth: 150 }}>
                  <ItemSelect                     
                    title={'Recorrência'} 
                    items={itemsTypeAssign} 
                    selectedValues={recurrence}
                    onChange={handleSelectRecorrence} 
                    minWidth={110}
                    labelWidth={90}
                    disabled={allowNext} 
                  />
                </Grid>
              {/* </Grid> */}
            </>
          )}
        {/* </Grid>         */}
        {storagePlans.length > 0 && storagePlans.map((tier, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12} sm={tier.title === 'ENTERPRISE' ? 12 : 12} md={12}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader} 
                  titleTypographyProps={{ align: 'center', className: classes.cardHeaderTitle }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'PROFESSIONAL' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h4" variant="h3" color="textPrimary" className={classes.cardPricingValue}>
                      {
                        <React.Fragment> 
                          {mode
                            ? (typeof tier.price === 'number'
                              ? tier.price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                              : parseFloat(tier.price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))
                            : tier.price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </React.Fragment>
                      }
                    </Typography>
                    {(!invoice.description && invoice.detail) && (
                      <Typography variant="h6" color="textSecondary">
                        {tier.recurrence ? `/${tier.recurrence}` : ''}
                      </Typography>
                    )}
                  </div>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant}
                    color="secondary"
                    onClick={() => {
                      if (tier.custom) {
                        setFieldValue("plan", JSON.stringify({
                          users: usersPlans,
                          connections: connectionsPlans,
                          price: customValuePlans,
                        }));
                      } else {
                        setFieldValue("plan", JSON.stringify(tier));
                      }
                      setActiveStep(activeStep + 1);
                    }
                    }
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </React.Fragment>
  );
}