import api from "../../services/api";

const useSettings = () => {

    const getAll = async (params) => {
        const { data } = await api.request({
            url: '/settings',
            method: 'GET',
            params
        });
        return data;
    }

    const getAllSettings = async (params) => {
        const { data } = await api.request({
            url: '/settings/all',
            method: 'GET',
            params
        });
        return data;
    }

    const find = async (setting) => {
        const { data } = await api.request({
            url: `/settings/${setting}`,
            method: 'GET',
        });
        return data;
    }

    const findValuesOf = async (keys) => {
        try {
          const { data } = await api.request({
            url: `/settings/multiple`,
            method: 'POST',
            data: { keys },
          });
       
          const valuesObject = data.reduce((acc, { key, value }) => {
            acc[key] = value;
            return acc;
          }, {});
      
          return valuesObject;
        } catch (error) { 
          console.error(error);
          throw error;
        }
    };    

    const update = async (data) => {
        const { data: responseData } = await api.request({
            url: `/settings/${data.key}`,
            method: 'PUT',
            data
        });
        return responseData;
    }

    return {
		getAll,
        getAllSettings,
        find,
        findValuesOf,
        update
    }
}

export default useSettings;