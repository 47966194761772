class formatMask {
  setPhoneFormatMask(phoneToFormat) {
    if(!phoneToFormat || phoneToFormat.length < 12){
      return phoneToFormat;
    }

    const number = ("" + phoneToFormat).replace(/\D/g, "");

    // if (number.length <= 12) {
    //   const phoneNumberFormatted = number.match(/^(\d{2})(\d{2})(\d{4})(\d{4})$/);
    //   return (
    //     "+" +
    //     phoneNumberFormatted[1] +
    //     " (" +
    //     phoneNumberFormatted[2] +
    //     ") " +
    //     phoneNumberFormatted[3] +
    //     "-" +
    //     phoneNumberFormatted[4]
    //   );
    // }else if(number.length === 13){
    //   const phoneNumberFormatted = number.match(/^(\d{2})(\d{2})(\d{5})(\d{4})$/);
    //   return (
    //     "+" +
    //     phoneNumberFormatted[1] +
    //     " (" +
    //     phoneNumberFormatted[2] +
    //     ") " +
    //     phoneNumberFormatted[3] +
    //     "-" +
    //     phoneNumberFormatted[4]
    //   );
    // } else {
    //   return phoneToFormat;
    // }
 
    // Detecta o código do país e define o formato adequado
    if (number.startsWith("55")) { // Brasil
      return this.applyMask(number, "+55", "🇧🇷");
    } else if (number.startsWith("1")) { // EUA e Canadá
      return this.applyMask(number, "+1", "🇺🇸");
    } else if (number.startsWith("44")) { // Reino Unido
      return this.applyMask(number, "+44", "🇬🇧");
    } else if (number.startsWith("49")) { // Alemanha
      return this.applyMask(number, "+49", "🇩🇪");
    } else if (number.startsWith("54")) { // Argentina
      return this.applyMask(number, "+54", "🇦🇷");
    } else if (number.startsWith("61")) { // Austrália
      return this.applyMask(number, "+61", "🇦🇺");
    } else if (number.startsWith("33")) { // França
      return this.applyMask(number, "+33", "🇫🇷");
    } else if (number.startsWith("81")) { // Japão
      return this.applyMask(number, "+81", "🇯🇵");
    } else if (number.startsWith("39")) { // Itália
      return this.applyMask(number, "+39", "🇮🇹");
    } else if (number.startsWith("52")) { // México
      return this.applyMask(number, "+52", "🇲🇽");
    } else if (number.startsWith("91")) { // Índia
      return this.applyMask(number, "+91", "🇮🇳");
    } else if (number.startsWith("86")) { // China
      return this.applyMask(number, "+86", "🇨🇳");
    } else if (number.startsWith("61")) { // Austrália
      return this.applyMask(number, "+61", "🇦🇺");
    } else if (number.startsWith("7")) { // Rússia
      return this.applyMask(number, "+7", "🇷🇺");
    } else if (number.startsWith("34")) { // Espanha
      return this.applyMask(number, "+34", "🇪🇸");
    } else if (number.startsWith("30")) { // Grécia
      return this.applyMask(number, "+30", "🇬🇷");
    } else if (number.startsWith("31")) { // Países Baixos
      return this.applyMask(number, "+31", "🇳🇱");
    } else if (number.startsWith("32")) { // Bélgica
      return this.applyMask(number, "+32", "🇧🇪");
    } else if (number.startsWith("33")) { // França
      return this.applyMask(number, "+33", "🇫🇷");
    } else if (number.startsWith("41")) { // Suíça
      return this.applyMask(number, "+41", "🇨🇭");
    } else if (number.startsWith("420")) { // República Tcheca
      return this.applyMask(number, "+420", "🇨🇿");
    } else if (number.startsWith("46")) { // Suécia
      return this.applyMask(number, "+46", "🇸🇪");
    } else if (number.startsWith("41")) { // Suíça
      return this.applyMask(number, "+41", "🇨🇭");
    } else if (number.startsWith("45")) { // Dinamarca
      return this.applyMask(number, "+45", "🇩🇰");
    } else if (number.startsWith("41")) { // Suíça
      return this.applyMask(number, "+41", "🇨🇭");
    } else if (number.startsWith("47")) { // Noruega
      return this.applyMask(number, "+47", "🇳🇴");
    } else if (number.startsWith("36")) { // Hungria
      return this.applyMask(number, "+36", "🇭🇺");
    } else if (number.startsWith("353")) { // Irlanda
      return this.applyMask(number, "+353", "🇮🇪");
    } else if (number.startsWith("972")) { // Israel
      return this.applyMask(number, "+972", "🇮🇱");
    } else if (number.startsWith("20")) { // Egito
      return this.applyMask(number, "+20", "🇪🇬");
    } else if (number.startsWith("60")) { // Malásia
      return this.applyMask(number, "+60", "🇲🇾");
    } else if (number.startsWith("63")) { // Filipinas
      return this.applyMask(number, "+63", "🇵🇭");
    } else if (number.startsWith("65")) { // Cingapura
      return this.applyMask(number, "+65", "🇸🇬");
    } else if (number.startsWith("27")) { // África do Sul
      return this.applyMask(number, "+27", "🇿🇦");
    } else if (number.startsWith("66")) { // Tailândia
      return this.applyMask(number, "+66", "🇹🇭");
    } else if (number.startsWith("82")) { // Coreia do Sul
      return this.applyMask(number, "+82", "🇰🇷");
    } else if (number.startsWith("351")) { // Portugal
      return this.applyMask(number, "+351", "🇵🇹");
    }
    // Adicione outros países conforme necessário

    return phoneToFormat; // Se o DDI não estiver listado    
  }

  applyMask(number, countryCode, flag) {
    // Adicionando logs para verificar os valores de entrada
    // console.log('Número original:', number);
    // console.log('Código do país:', countryCode);
  
    // Remover o DDI do número
    const cleanNumber = number.replace(/^\+?55/, '').replace(/\D/g, '');
  
    // Log do número limpo
    // console.log('Número limpo:', cleanNumber);
  
    let phoneNumberFormatted;
  
    // Aqui definimos as expectativas de comprimento após a remoção do código do país
    if (cleanNumber.length === 10) {
      // Número com 10 dígitos no formato de telefone fixo
      phoneNumberFormatted = cleanNumber.match(/^(\d{2})(\d{4})(\d{4})$/);
    } else if (cleanNumber.length === 11) {
      // Número com 11 dígitos (celular com dígito extra)
      phoneNumberFormatted = cleanNumber.match(/^(\d{2})(\d{5})(\d{4})$/);
    } else {
      // Verificação de comprimento não corresponde
      // console.log('Retornando número sem formatação, comprimento não corresponde:', cleanNumber);
      return number; // Retorna o número sem formatação se não corresponder
    }
  
    // Log do formato encontrado
    // console.log('phoneNumberFormatted:', phoneNumberFormatted);
  
    if (!phoneNumberFormatted) {
      // console.log('Nenhuma correspondência encontrada. Retornando número original.');
      return number; // Se não houver correspondência, retorna o número original
    }
  
    const formattedNumber = `${flag} (${phoneNumberFormatted[1]}) ${phoneNumberFormatted[2]}-${phoneNumberFormatted[3]}`;
    // console.log('Número formatado:', formattedNumber); // Log do número formatado
  
    return formattedNumber;
  }   

  removeMask(number) {
    const filterNumber = number.replace(/\D/g, "");
    return filterNumber;
  }

  maskPhonePattern(phoneNumber){
    // Detecta o país e ajusta a máscara automaticamente 
    if (phoneNumber.startsWith("55")) {
      return phoneNumber.length <= 13 ? '🇧🇷 (99) 9999-9999' : '🇧🇷 (99) 99999-9999';
    } else if (phoneNumber.startsWith("1")) {
      return '🇺🇸 (999) 999-9999';
    } else if (phoneNumber.startsWith("44")) {
      return '🇬🇧 9999 999 9999';
    } else if (phoneNumber.startsWith("49")) {
      return phoneNumber.length <= 13 ? '🇩🇪 (99) 9999-9999' : '🇩🇪 (99) 99999-9999';
    } else if (phoneNumber.startsWith("54")) {
      return phoneNumber.length <= 10 ? '🇦🇷 (99) 9999-9999' : '🇦🇷 (99) 99999-9999';
    } else if (phoneNumber.startsWith("61")) {
      return phoneNumber.length <= 10 ? '🇦🇺 (99) 9999-9999' : '🇦🇺 (99) 99999-9999';
    } else if (phoneNumber.startsWith("33")) {
      return '🇫🇷 01 23 45 67 89';
    } else if (phoneNumber.startsWith("81")) {
      return '🇯🇵 (99) 9999-9999';
    } else if (phoneNumber.startsWith("39")) {
      return phoneNumber.length <= 10 ? '🇮🇹 0# #### ####' : '🇮🇹 0# ##### ####';
    } else if (phoneNumber.startsWith("52")) {
      return phoneNumber.length <= 10 ? '🇲🇽 (99) 9999-9999' : '🇲🇽 (99) 99999-9999';
    } else if (phoneNumber.startsWith("91")) {
      return '🇮🇳 +91 99999 99999';
    } else if (phoneNumber.startsWith("86")) {
      return '🇨🇳 +86 10 1234 5678';
    } else {
      return phoneNumber; // Retorna o número sem formatação se não corresponder
    }
  }
}

export { formatMask };