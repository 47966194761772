import React from "react";
import { Modal, Backdrop, Fade, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2, 4, 3)
  },
  image: {
    width: "100%",
    userSelect: "none",
    pointerEvents: "none",
  },
}));

const ImageModal = ({ src, alt, open, onClose }) => {
  const classes = useStyles();

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <img
            src={src}
            alt={alt}
            className={classes.image}
            onContextMenu={handleContextMenu}  
            draggable="false" 
          />
        </div>
      </Fade>
    </Modal>
  );
};

export default ImageModal;
