import React from "react";

export const applyFormatting = text => {
  // Aplicando negrito (*texto*)
  text = text.replace(/\*(.*?)\*/g, "<strong>$1</strong>");
  // Aplicando itálico (_texto_)
  text = text.replace(/_(.*?)_/g, "<em>$1</em>");
  // Aplicando sublinhado (~texto~)
  text = text.replace(/~(.*?)~/g, "<u>$1</u>");
  // Aplicando tachado (~texto~)
  text = text.replace(/~(.*?)~/g, "<del>$1</del>");
  // Aplicando monoespaçado (```texto```)
  text = text.replace(/```(.*?)```/g, "<code>$1</code>");

  return text;
};

export const formatWithoutBreakLines = text => {
  // Remove as quebras de linha e substitui por espaços
  const formattedText = String(text).replace(/\n/g, " ");

  // Aplica a formatação ao texto sem quebras de linha
  return (
    <span
      dangerouslySetInnerHTML={{ __html: applyFormatting(formattedText) }}
    />
  );
};

export const formatAndBreakLines = text => {
  return String(text)
    .split("\n")
    .map((line, index) => (
      <React.Fragment key={index}>
        <span dangerouslySetInnerHTML={{ __html: applyFormatting(line) }} />
        <br />
      </React.Fragment>
    ));
};
