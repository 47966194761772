import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';

function PaymentDetails(props) {
  const { formValues } = props;
  const classes = useStyles();
  const { plan } = formValues; 
  const data = JSON.parse(plan); 
  const title = data.useDescription ? 'Cobrança adicional' : 'Detalhes do plano';
 
  return (
    <Grid item xs={12} sm={12}>
      <Typography variant="h6" gutterBottom className={classes.title}>
        { title }
      </Typography>
      {!data.useDescription ? (
        <>
          <Typography gutterBottom>{data.description[0]}</Typography>
          <Typography gutterBottom>{data.description[1]}</Typography>
          <Typography gutterBottom>{data.description[2]}</Typography>
          {/* <Typography gutterBottom>{data.description[3]}</Typography> */}
        </>
      ) : (
        <Typography gutterBottom>{data.description[0]}</Typography>
      )}
      <Typography gutterBottom>
        Total: {(typeof data.price === 'number'
                  ? data.price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                  : parseFloat(data.price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))} 
        {data.recurrence ? '/' + data.recurrence : ''}
      </Typography>
    </Grid>
  );
}

export default PaymentDetails;
