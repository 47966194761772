import React, { useState, useEffect } from "react";

import { Avatar, CardHeader } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import ImageModal from "../ImageModal";

const TicketInfo = ({ contact, ticket, onClick }) => {
	const { user, queue } = ticket;
	const [userName, setUserName] = useState('');
	const [contactName, setContactName] = useState('');

	const [openAvatarModal, setOpenAvatarModal] = useState(false);
	const [selectedImageAvatarModal, setSelectedImageAvatarModal] = useState("");
  
	useEffect(() => {
		if (contact) {
			setContactName(contact.name);
			if(document.body.offsetWidth < 600) {
				if (contact.name.length > 10) {
					const truncadName = contact.name.substring(0, 10) + '...';
					setContactName(truncadName);
				}
			}
		}

		if (user && contact) {
			setUserName(`${i18n.t("messagesList.header.assignedTo")} ${user.name} 
			${queue ? " | Depto: " + queue.name : " | Depto: Nenhum"}`);

			if (document.body.offsetWidth < 600) {
				setUserName(`${user.name} ${queue ? " | " + queue.name : ""}`);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [])

	const handleOpenAvatarModal = imageUrl => {
		setSelectedImageAvatarModal(imageUrl);
		setOpenAvatarModal(true);
	  };
	
	  const handleCloseAvatarModal = () => {
		setOpenAvatarModal(false);
		setSelectedImageAvatarModal("");
	  };
	
	return (
		<>
		<ImageModal
			src={selectedImageAvatarModal}
			alt=""
			open={openAvatarModal}
			onClose={handleCloseAvatarModal}
		/>
		<CardHeader
			onClick={onClick}
			style={{ cursor: "pointer" }}
			titleTypographyProps={{ noWrap: true }}
			subheaderTypographyProps={{ noWrap: true }}
			avatar={ 
				<Avatar 
					style={{ cursor: "pointer" }}
					src={contact.profilePicUrl}
					alt="" 
					onClick={() =>
						!contact.profilePicUrl.includes("nopicture")
						? handleOpenAvatarModal(contact.profilePicUrl)
						: null
					} 
				/>
			}
			title={`${contactName} #${ticket.id}`}
			subheader={ticket.user && `${userName}`}
		/>
		</>
	);
};

export default TicketInfo;
