import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import UndoRoundedIcon from "@material-ui/icons/UndoRounded";
import Tooltip from "@material-ui/core/Tooltip"; 
import NewTicketModal from "../NewTicketModal";
import { BiSend } from "react-icons/bi";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  actionButtons: {
    marginRight: 6,
    flex: "none",
    alignSelf: "center",
    marginLeft: "auto",
    "& > *": {
      margin: theme.spacing(0.5)
    }
  },
  invertedFabMenuBase: {
    borderRadius: 50, // Valor padrão, pode ser sobrescrito
    boxShadow: "none", // Remove a sombra
    width: theme.spacing(4), // Ajuste o tamanho de acordo com suas preferências
    height: theme.spacing(4),
    backgroundColor: "transparent",
    color: "grey",
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "transparent",
      transform: "scale(1.4)", // Animação de escala
      color: theme.mode === "light" ? theme.palette.primary.main : "#EEE"
    },
    "&:disabled": {
      backgroundColor: "transparent !important"
    }
  },
  icon: {
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      color: theme.mode === "light" ? theme.palette.primary.main : "#EEE"
    }
  },
  menuItem: {
    "&:hover $icon": {
      transform: "scale(1.4)"
    }
  }
}));

const TicketActionButtonsCustom = ({
  ticket,
  showSelectMessageCheckbox,
  selectedMessages,
  forwardMessageModalOpen,
  setForwardMessageModalOpen
}) => {
  const classes = useStyles();
  
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const ticketOptionsMenuOpen = Boolean(anchorEl);
  const { user } = useContext(AuthContext);
  const { setCurrentTicket } = useContext(TicketsContext);
  const [reopenTicketModalOpen, setReopenTicketModalOpen] = useState(false);

  const handleOpenTicketOptionsMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseTicketOptionsMenu = e => {
    setAnchorEl(null);
  };

  const handleOpenModalForward = () => {
    if (selectedMessages.length === 0) {
      toastError({
        response: { data: { message: "Nenhuma mensagem selecionada" } }
      });
      return;
    }
    setForwardMessageModalOpen(true);
  };

  const handleUpdateTicketStatus = async (e, status, userId) => {
    setLoading(true);
    const queueId = e && e.target ? e.target.value : e; // Verifica se é um evento sintético e obtém o valor apropriado
    try {
      await api.put(`/tickets/${ticket.id}`, {
        queueId: queueId,
        status: status,
        userId: userId || null,
        useIntegration: status === "closed" ? false : ticket.useIntegration,
        isPrompt: status === "closed" ? false : ticket.isPrompt,
        integrationId: status === "closed" ? false : ticket.integrationId
      });

      setLoading(false);
      if (status === "open") {
        setCurrentTicket({ ...ticket, code: "#open" });
        window.location.reload();
      } else {
        setCurrentTicket({ id: null, code: null });
        history.push("/tickets");
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const handleReopenTicketModalOpen = () => {
    setReopenTicketModalOpen(true);
  };

  return (
    <div className={classes.actionButtons}>
      {reopenTicketModalOpen && (
        <NewTicketModal
          modalOpen={true}
          initialContact={ticket.contact}
          isReopen={true}
          onClose={value => {
            setReopenTicketModalOpen(false);
            if (value && value !== null)
              handleUpdateTicketStatus(value, "open", user?.id);
          }}
        />
      )}
      {ticket.status === "closed" && (
        <ButtonWithSpinner
          loading={loading}
          startIcon={<Replay />}
          variant="contained"
          color="primary"
          onClick={() => handleReopenTicketModalOpen()}
        >
          {i18n.t("messagesList.header.buttons.reopen")}
        </ButtonWithSpinner>
      )}
      {ticket.status === "open" && (
        <>
          {!showSelectMessageCheckbox ? (
            <>
              <Tooltip title={i18n.t("messagesList.header.buttons.return")}>
                <IconButton
				  className={classes.invertedFabMenuBase}
                  onClick={e => handleUpdateTicketStatus(e, "pending", null)}
                >
                  <UndoRoundedIcon className={classes.icon} />
                </IconButton>
              </Tooltip>

              <Tooltip title={i18n.t("messagesList.header.buttons.resolve")}>
                <IconButton
				  className={classes.invertedFabMenuBase}
                  onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
                  color="primary"
                >
                  <CheckCircleIcon className={classes.icon} style={{color: green[600]}} />
                </IconButton>
              </Tooltip>
              {/* <ButtonWithSpinner
						loading={loading}
						startIcon={<Replay />} 
						onClick={e => handleUpdateTicketStatus(e, "pending", null)}
					>
						{i18n.t("messagesList.header.buttons.return")}
					</ButtonWithSpinner>
					<ButtonWithSpinner
						loading={loading} 
						variant="contained"
						color="primary"
						onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
					>
						{i18n.t("messagesList.header.buttons.resolve")}
					</ButtonWithSpinner> */}
              <Tooltip title={'Mais opções'}>
                <IconButton 
					className={classes.invertedFabMenuBase}
					onClick={handleOpenTicketOptionsMenu}
				>
                  <MoreVert className={classes.icon} />
                </IconButton>
              </Tooltip>
              <TicketOptionsMenu
                ticket={ticket}
                anchorEl={anchorEl}
                menuOpen={ticketOptionsMenuOpen}
                handleClose={handleCloseTicketOptionsMenu}
              />
            </>
          ) : (
            <ButtonWithSpinner
              loading={loading}
              startIcon={<BiSend />}
              size="small"
              onClick={handleOpenModalForward}
            >
              Encaminhar
            </ButtonWithSpinner>
          )}
        </>
      )}
      {ticket.status === "pending" && (
        <ButtonWithSpinner
          loading={loading}
          variant="contained"
          color="primary"
          onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
        >
          {i18n.t("messagesList.header.buttons.accept")}
        </ButtonWithSpinner>
      )}
    </div>
  );
};

export default TicketActionButtonsCustom;
