import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const UserAvatar = ({ src, status, statusColor }) => {
  return (
    <div className="user-avatar-container">
      <img src={src} alt="User Avatar" className="user-avatar" />
      {status && <div className="status-indicator" style={{ backgroundColor: statusColor }}></div>}
    </div>
  );
};

UserAvatar.propTypes = {
  src: PropTypes.string.isRequired,
  status: PropTypes.bool,
  statusColor: PropTypes.string,
};

UserAvatar.defaultProps = {
  statusColor: '#4caf50', // Cor verde padrão
};

export default UserAvatar;
