import React, { createContext, useState } from "react";

const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
	const [makeRequest, setMakeRequest] = useState(null);
	const [makeRequestTag, setMakeRequestTag] = useState(null);
	const [makeRequestWhatsapp, setMakeRequestWhatsapp] = useState(null);
	const [makeRequestTagTotalTicketPending, setMakeRequestTagTotalTicketPending] = useState(0);
	const [openTabTicket, setOpenTabTicket] = useState({
		tab: '',
		makeRequest: 0
	});

	return (
		<GlobalContext.Provider
			value={{
				makeRequest,
				makeRequestTag,
				openTabTicket,
				makeRequestTagTotalTicketPending,
				makeRequestWhatsapp,
				setMakeRequestWhatsapp,
				setMakeRequest,
				setMakeRequestTag,
				setOpenTabTicket,
				setMakeRequestTagTotalTicketPending,
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
};

export { GlobalContext, GlobalContextProvider };
