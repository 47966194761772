import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
// import ColorPicker from "../ColorPicker";
import { 
  Grid,
  IconButton,
  InputAdornment, 
  Paper, 
  Tab,
  Tabs,
} from "@material-ui/core";
import { Colorize } from "@material-ui/icons";
import { QueueOptions } from "../QueueOptions";
import SchedulesForm from "../SchedulesForm";
import usePlans from "../../hooks/usePlans";
import { AuthContext } from "../../context/Auth/AuthContext";
import ItemSelect from "../ItemSelect";
import HelperTextWithChips from "../HelperTextWithChips";
import useSettings from "../../hooks/useSettings";
import ColorBoxModal from "../ColorBoxModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
}));

const QueueSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Muito curto!")
    .max(50, "Muito longo!")
    .required("Obrigatório"),
  color: Yup.string().min(3, "Muito curto!").max(9, "Muito longo!").required(),
  greetingMessage: Yup.string(),
});

const QueueModal = ({ open, onClose, queueId }) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    color: "",
    greetingMessage: "",
    outOfHoursMessage: "",
    integrationId: 0,
    orderQueue: "",
  };

  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
  const [queue, setQueue] = useState(initialState);
  const [tab, setTab] = useState(0);
  const [schedulesEnabled, setSchedulesEnabled] = useState(false);
  const greetingRef = useRef();
  const [integrationId, setIntegrationId] = useState('0'); 
  const [integrations, setIntegrations] = useState([]);
  const [useIntegration, setUseIntegration] = useState(false);

  const { user } = useContext(AuthContext);
  const { findValuesOf } = useSettings();
  const { finder: listPlans } = usePlans();
  
  useEffect(() => {
    async function fetchData() {
      const planData = await listPlans(user.company.planId);
      setUseIntegration(planData.useIntegration || false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  const [schedules, setSchedules] = useState([
    { weekday: "Domingo", weekdayEn: "sunday",startTime: "00:00",endTime: "00:00",},
    { weekday: "Segunda-feira",weekdayEn: "monday",startTime: "08:00",endTime: "18:00",},
    { weekday: "Terça-feira",weekdayEn: "tuesday",startTime: "08:00",endTime: "18:00",},
    { weekday: "Quarta-feira",weekdayEn: "wednesday",startTime: "08:00",endTime: "18:00",},
    { weekday: "Quinta-feira",weekdayEn: "thursday",startTime: "08:00",endTime: "18:00",},
    { weekday: "Sexta-feira", weekdayEn: "friday",startTime: "08:00",endTime: "18:00",},
    { weekday: "Sábado", weekdayEn: "saturday",startTime: "08:00",endTime: "12:00",},
  ]);
 
  useEffect(() => {
    const fetchSession = async () => {
      const values = await findValuesOf(['scheduleType']);
      setSchedulesEnabled(values.scheduleType === 'queue'); 
    }
    fetchSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
    
  useEffect(() => {
    (async () => {
      if (!queueId) return;
      try {
        const { data } = await api.get(`/queue/${queueId}`);
        setIntegrationId(data.integrationId);
        setQueue((prevState) => {
          return { ...prevState, ...data };
        }); 
        setSchedules(data.schedules);
      } catch (err) {
        toastError(err);
      }
    })();

    return () => {
      setQueue({
        name: "",
        color: "",
        greetingMessage: "",
        outOfHoursMessage: "",
        integrationId: 0,
        orderQueue: "",
      });
    };
  }, [queueId, open]);

  useEffect(() => {
    (async () => {
      try {
        if (useIntegration && useIntegration === true) {
          const { data } = await api.get("/queueIntegration");
          const itemsIntegrationData = [
            { value: 0, name: "Nenhuma" },
            ...(data.queueIntegrations || [])
              .filter(integration => integration.type !== "")
              .map(integration => ({ value: integration.id, name: integration.name })),
          ];            
          setIntegrations(itemsIntegrationData);
        }
      } catch (err) {
        toastError(err);
      }
    })();
  }, [useIntegration]);
  
  const handleClose = () => {
    onClose();
    setIntegrationId('0');
    setQueue(initialState);
  };

  const handleSaveQueue = async (values) => {
    try {
      values.integrationId = integrationId;
      if (!integrationId || !(parseInt(integrationId) > 0))
        values.integrationId = null; 

      if (queueId) {
        await api.put(`/queue/${queueId}`, { ...values, schedules });
      } else {
        await api.post("/queue", { ...values, schedules });
      }
      toast.success("Fila salvo com sucesso!");
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleSaveSchedules = async (values) => {
    toast.success("Clique em salvar para registrar as alterações");
    setSchedules(values);
    setTab(0);
  };

  const handleIntegrationChange = (selectedId) => {
    setIntegrationId(selectedId);
  };

  const handleChipClick = async (chipValue, elementId, setValueFunc) => { 
    if (!chipValue || !elementId) return;
  
    const textField = document.getElementById(elementId);
    
    if (textField) { 
      const cursorPosition = textField.selectionStart;
      const start = textField.value.slice(0, cursorPosition);
      const end = textField.value.slice(cursorPosition);   

      setValueFunc(elementId, `${start}${chipValue}${end}`);

      textField.setSelectionRange(cursorPosition + chipValue.length, cursorPosition + chipValue.length);       
    }   
  };  
   
  return (
    <div className={classes.root}>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        scroll="paper"
      >
        <DialogTitle>
          {queueId
            ? `${i18n.t("queueModal.title.edit")}`
            : `${i18n.t("queueModal.title.add")}`}
        </DialogTitle>
        <Tabs
          value={tab} 
          onChange={(_, v) => setTab(v)}
          aria-label="disabled tabs example"
        >
          <Tab label="Dados da Fila" />
          {schedulesEnabled && <Tab label="Horários de atendimento" />}
        </Tabs>
        {tab === 0 && (
          <Paper>
            <Formik
              initialValues={queue}
              enableReinitialize={true}
              validationSchema={QueueSchema}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  handleSaveQueue(values);
                  actions.setSubmitting(false);
                }, 400);
              }}
            >
              {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                <Form>
                  <DialogContent dividers>
                    <Grid spacing={1} container style={{ marginBottom: 3 }}>
                      <Grid xs={12} sm={12} md={3} item>
                        <Field
                          as={TextField}
                          label={"Nome"}
                          autoFocus
                          fullWidth
                          name="name"
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                          variant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid xs={12} sm={6} md={3} item>
                        <Field
                          as={TextField}
                          label={"Cor"}
                          fullWidth
                          name="color"
                          id="color"
                          onFocus={() => {
                            setColorPickerModalOpen(true);
                            greetingRef.current.focus();
                          }}
                          error={touched.color && Boolean(errors.color)}
                          helperText={touched.color && errors.color}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <div
                                  style={{ backgroundColor: values.color, width: "20px", height: "20px" }}
                                ></div>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <IconButton size="small" color="default">
                                <Colorize />
                              </IconButton>
                            ),
                          }}
                          variant="outlined"
                          margin="dense"
                        /> 
                        <ColorBoxModal
                          open={colorPickerModalOpen}
                          handleClose={() => setColorPickerModalOpen(false)}
                          onChange={(color) => {
                            setFieldValue("color", `#${color.hex}`);
                          }}
                          currentColor={values.color}
                        />
                        {/* <ColorPicker
                          open={colorPickerModalOpen}
                          handleClose={() => setColorPickerModalOpen(false)}
                          onChange={(color) => {
                            values.color = color;
                            setQueue(() => {
                              return { ...values, color };
                            });
                          }}
                        /> */}
                      </Grid>
                      <Grid xs={12} sm={6} md={2} item> 
                      <Field
                        as={TextField}
                        label={i18n.t("queueModal.form.orderQueue")}
                        fullWidth
                        name="orderQueue"
                        type="orderQueue"
                        error={touched.orderQueue && Boolean(errors.orderQueue)}
                        helperText={touched.orderQueue && errors.orderQueue}
                        variant="outlined"
                        margin="dense" 
                      />
                      </Grid>
                      <Grid xs={12} sm={12} md={4} item> 
                        <ItemSelect 
                          fullWidth
                          disabled={isSubmitting}
                          title={i18n.t("queueModal.form.integrationId")}
                          selectedValues={integrationId || '0'}
                          items={integrations} 
                          onChange={handleIntegrationChange} 
                          labelWidth={80} 
                        />
                      </Grid>
                    </Grid>                   
                    <div style={{ marginTop: 5 }}>
                          <Field
                            as={TextField}
                            label={i18n.t("queueModal.form.greetingMessage")}
                            type="greetingMessage"
                            multiline
                            inputRef={greetingRef}
                            rows={5}
                            fullWidth
                            id="greetingMessage" 
                            name="greetingMessage" 
                            error={
                              touched.greetingMessage &&
                              Boolean(errors.greetingMessage)
                            } 
                            variant="outlined"
                            margin="dense"                            
                            helperText={
                              <HelperTextWithChips onChipClick={(chipValue) => 
                                  handleChipClick(chipValue, 'greetingMessage', setFieldValue)
                                } 
                              />
                            }
                          />
                        {schedulesEnabled && (
                            <Field
                              as={TextField}
                              label={i18n.t("queueModal.form.outOfHoursMessage")}
                              type="outOfHoursMessage"
                              multiline
                              inputRef={greetingRef}
                              rows={5}
                              fullWidth
                              id="outOfHoursMessage"
                              name="outOfHoursMessage"
                              error={
                                touched.outOfHoursMessage &&
                                Boolean(errors.outOfHoursMessage)
                              } 
                              variant="outlined"
                              margin="dense"                            
                              helperText={
                                <HelperTextWithChips onChipClick={(chipValue) => 
                                    handleChipClick(chipValue, 'outOfHoursMessage', setFieldValue)
                                  } 
                                />
                              }
                            />
                        )}
                    </div>
                    <QueueOptions queueId={queueId} />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      color="secondary"
                      disabled={isSubmitting}
                      variant="outlined"
                    >
                      {i18n.t("queueModal.buttons.cancel")}
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                      variant="contained"
                      className={classes.btnWrapper}
                    >
                      {queueId
                        ? `${i18n.t("queueModal.buttons.okEdit")}`
                        : `${i18n.t("queueModal.buttons.okAdd")}`}
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Paper>
        )}
        {tab === 1 && (
          <Paper style={{ padding: 20 }}>
            <SchedulesForm
              loading={false}
              onSubmit={handleSaveSchedules}
              initialValues={schedules}
              labelSaveButton="Adicionar"
            />
          </Paper>
        )}
      </Dialog>
    </div>
  );
};

export default QueueModal;
