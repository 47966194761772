import React, { useEffect, useState } from "react";

import {
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  FormControlLabel,
  InputLabel,
  Paper,
  TextField
} from "@material-ui/core";

import IOSSwitch from "../IOSSwitch";
import FileInput from "../FileInput";

import useSettings from "../../hooks/useSettings";
import { grey, blue } from "@material-ui/core/colors";
// import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth.js";
import OnlyForSuperUser from "../OnlyForSuperUser";
// import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240
  },
  tab: {
    background: "#f2f5f3",
    borderRadius: 4,
    width: "100%",
    "& .MuiTab-wrapper": {
      color: "#128c7e"
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center"
    }
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
    width: "100%"
  },
  cardAvatar: {
    fontSize: "55px",
    color: grey[500],
    backgroundColor: "#ffffff",
    width: theme.spacing(7),
    height: theme.spacing(7)
  },
  cardTitle: {
    fontSize: "18px",
    color: blue[700]
  },
  cardSubtitle: {
    color: grey[600],
    fontSize: "14px"
  },
  alignRight: {
    textAlign: "right"
  },
  fullWidth: {
    width: "100%"
  },
  selectContainer: {
    width: "100%",
    textAlign: "left"
  },

  root: {
    boxShadow: theme.shadows[8]
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1)
  },
  controlContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },
  switchLabel: {
    marginRight: theme.spacing(2)
  },
  textField: {
    marginRight: theme.spacing(2)
  }
}));

export default function Options(props) {
  const { settings, scheduleTypeChanged } = props;
  const classes = useStyles();
  const [currentUser, setCurrentUser] = useState({});
  const { getCurrentUserInfo } = useAuth();
  const [userRating, setUserRating] = useState("disabled");
  const [scheduleType, setScheduleType] = useState("disabled");
  const [callType, setCallType] = useState("enabled");
  const [chatbotType, setChatbotType] = useState("");
  const [CheckMsgIsGroup, setCheckMsgIsGroupType] = useState("enabled");
  const [addAlterConnection, setAddAlterConnection] = useState("enabled");
  const [userViewTicketsWithoutDepartment, setUserViewTicketsWithoutDepartment] = useState("disabled");
  const [newLeadsNotWriteName, setNewLeadsNotWriteName] = useState("disabled");
  const [userAlwaysSubmitSignature, setUserAlwaysSubmitSignature] = useState("enabled");
 
  const [webhookType, setWebhookType] = useState("");

  const { update } = useSettings();

  const [expanded, setExpanded] = useState(false);
  const [efiSandbox, setEfiSandboxEnabled] = useState(true);
  const [efiClientId, setEfiClientIdEnabled] = useState("");
  const [efiClientSecret, setEfiClientSecretEnabled] = useState("");
  const [efiChavePix, setEfiChavePixEnabled] = useState("");

  const [facebookClientId, setFacebookClientIdEnabled] = useState("");
  const [facebookClientSecret, setFacebookClientSecretEnabled] = useState("");

  // Send Email
  const [sendEmailEnabled, setSendEmailEnabled] = useState(true);
  const [apiKeySendGrid, setApiKeySendGrid] = useState("");
  const [idEmailRegister, setIdEmailRegister] = useState("");
  const [idEmailInvite, setIdEmailInvite] = useState("");
  const [idEmailRecoveryPassword, setIdEmailRecoveryPassword] = useState("");
  const [idEmailSendInvoice, setIdEmailSendInvoice] = useState("");
  const [idEmailOverdueInvoice, setIdEmailOverdueInvoice] = useState("");
  const [idEmailInvoicePaid, setIdEmailInvoicePaid] = useState("");

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    async function findData() {
      try {
        const user = await getCurrentUserInfo();
        setCurrentUser(user);
      } catch (e) {
        toast.error(e);
      }
    }
    findData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(settings) && settings.length) {
      const userRating = settings.find(s => s.key === "userRating");
      if (userRating) {
        setUserRating(userRating.value);
      }
      const scheduleType = settings.find(s => s.key === "scheduleType");
      if (scheduleType) {
        setScheduleType(scheduleType.value);
      }
      const callType = settings.find(s => s.key === "call");
      if (callType) {
        setCallType(callType.value);
      }
      const CheckMsgIsGroup = settings.find(s => s.key === "CheckMsgIsGroup");
      if (CheckMsgIsGroup) {
        setCheckMsgIsGroupType(CheckMsgIsGroup.value);
      }
      setChatbotType('text');
      const chatbotType = settings.find(s => s.key === "chatBotType");
      if (chatbotType) {
        setChatbotType(chatbotType.value);
      } 
      const webhookType = settings.find(s => s.key === "webhook");
      if (webhookType) {
        setWebhookType(webhookType.value);
      }
      const efiSandboxType = settings.find(s => s.key === "efiSandbox");
      if (efiSandboxType) {
        setEfiSandboxEnabled(efiSandboxType.value);
      }
      const efiClientIdType = settings.find(s => s.key === "efiClientId");
      if (efiClientIdType) {
        setEfiClientIdEnabled(efiClientIdType.value);
      }
      const efiClientSecretType = settings.find(
        s => s.key === "efiClientSecret"
      );
      if (efiClientSecretType) {
        setEfiClientSecretEnabled(efiClientSecretType.value);
      }
      const efiChavePixType = settings.find(s => s.key === "efiChavePix");
      if (efiChavePixType) {
        setEfiChavePixEnabled(efiChavePixType.value);
      }
      const facebookClientIdType = settings.find(
        s => s.key === "facebookClientId"
      );
      if (facebookClientIdType) {
        setFacebookClientIdEnabled(facebookClientIdType.value);
      }
      const facebookClientSecretType = settings.find(
        s => s.key === "facebookClientSecret"
      );
      if (facebookClientSecretType) {
        setFacebookClientSecretEnabled(facebookClientSecretType.value);
      }

      const addAlterConnectionType = settings.find(
        s => s.key === "addAlterConnection"
      );
      if (addAlterConnectionType) {
        setAddAlterConnection(addAlterConnectionType.value);
      }
      const userViewTicketsWithoutDepartmentType = settings.find(
        s => s.key === "userViewTicketsWithoutDepartment"
      );
      if (userViewTicketsWithoutDepartmentType) {
        setUserViewTicketsWithoutDepartment(
          userViewTicketsWithoutDepartmentType.value
        );
      }
      const newLeadsNotWriteNameType = settings.find(
        s => s.key === "newLeadsNotWriteName"
      );
      if (newLeadsNotWriteNameType) {
        setNewLeadsNotWriteName(newLeadsNotWriteNameType.value);
      }
      const userAlwaysSubmitSignatureType = settings.find(
        s => s.key === "userAlwaysSubmitSignature"
      );
      if (userAlwaysSubmitSignatureType) {
        setUserAlwaysSubmitSignature(userAlwaysSubmitSignatureType.value);
      }

      // Emails
      const sendEmailEnabledType = settings.find(
        s => s.key === "sendEmailEnabled"
      );
      if (sendEmailEnabledType) setSendEmailEnabled(sendEmailEnabledType.value);

      const apiKeySendGridType = settings.find(s => s.key === "apiKeySendGrid");
      if (apiKeySendGridType) setApiKeySendGrid(apiKeySendGridType.value);

      const idEmailRegisterType = settings.find(
        s => s.key === "idEmailRegister"
      );
      if (idEmailRegisterType) setIdEmailRegister(idEmailRegisterType.value);

      const idEmailInviteType = settings.find(s => s.key === "idEmailInvite");
      if (idEmailInviteType) setIdEmailInvite(idEmailInviteType.value);

      const idEmailRecoveryPasswordType = settings.find(
        s => s.key === "idEmailRecoveryPassword"
      );
      if (idEmailRecoveryPasswordType)
        setIdEmailRecoveryPassword(idEmailRecoveryPasswordType.value);

      const idEmailSendInvoiceType = settings.find(
        s => s.key === "idEmailSendInvoice"
      );
      if (idEmailSendInvoiceType)
        setIdEmailSendInvoice(idEmailSendInvoiceType.value);

      const idEmailOverdueInvoiceType = settings.find(
        s => s.key === "idEmailOverdueInvoice"
      );
      if (idEmailOverdueInvoiceType)
        setIdEmailOverdueInvoice(idEmailOverdueInvoiceType.value);

      const idEmailInvoicePaidType = settings.find(
        s => s.key === "idEmailInvoicePaid"
      );
      if (idEmailInvoicePaidType)
        setIdEmailInvoicePaid(idEmailInvoicePaidType.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const handleUserRatingChange = async e => {
    const { checked } = e.target;
    const value = checked ? "enabled" : "disabled";
    setUserRating(value);
    try {
      await update({
        key: "userRating",
        value
      });
      toast.success("Parâmetro atualizado com sucesso.");
    } catch (err) {
      setUserRating(userRating);
      toast.error("Erro ao atualizar parâmetro.");
    }
  };

  const handleEfiSandboxChange = async e => {
    const { checked } = e.target;
    const value = checked ? "enabled" : "disabled";
    setEfiSandboxEnabled(value);
    try {
      await update({
        key: "efiSandbox",
        value
      });
      toast.success("Parâmetro atualizado com sucesso.");
    } catch (err) {
      setEfiSandboxEnabled(efiSandbox);
      toast.error("Erro ao atualizar parâmetro.");
    }
  };

  async function handleChangeEfiClientId(value) {
    setEfiClientIdEnabled(value);
    try {
      await update({
        key: "efiClientId",
        value
      });
      toast.success("Parâmetro atualizado com sucesso.");
    } catch (err) {
      setEfiClientIdEnabled(efiClientId);
      toast.error("Erro ao atualizar parâmetro.");
    }
  }

  async function handleChangeEfiClientSecret(value) {
    setEfiClientSecretEnabled(value);
    try {
      await update({
        key: "efiClientSecret",
        value
      });
      toast.success("Parâmetro atualizado com sucesso.");
    } catch (err) {
      setEfiClientSecretEnabled(efiClientSecret);
      toast.error("Erro ao atualizar parâmetro.");
    }
  }

  async function handleChangeEfiChavePix(value) {
    setEfiChavePixEnabled(value);
    try {
      await update({
        key: "efiChavePix",
        value
      });
      toast.success("Parâmetro atualizado com sucesso.");
    } catch (err) {
      setEfiClientSecretEnabled(efiChavePix);
      toast.error("Erro ao atualizar parâmetro.");
    }
  }

  async function handleChangeFacebookClientId(value) {
    setFacebookClientIdEnabled(value);
    try {
      await update({
        key: "facebookClientId",
        value
      });
      toast.success("Parâmetro atualizado com sucesso.");
    } catch (err) {
      setFacebookClientIdEnabled(facebookClientId);
      toast.error("Erro ao atualizar parâmetro.");
    }
  }

  async function handleChangeFacebookClientSecret(value) {
    setFacebookClientSecretEnabled(value);
    try {
      await update({
        key: "facebookClientSecret",
        value
      });
      toast.success("Parâmetro atualizado com sucesso.");
    } catch (err) {
      setFacebookClientSecretEnabled(facebookClientSecret);
      toast.error("Erro ao atualizar parâmetro.");
    }
  }

  async function handleScheduleType(value) {
    setScheduleType(value);
    await update({
      key: "scheduleType",
      value
    });

    toast.success("Parâmetro atualizado com sucesso.");

    if (typeof scheduleTypeChanged === "function") {
      scheduleTypeChanged(value);
    }
  }

  const handleCallTypeChange = async e => {
    const { checked } = e.target;
    const value = checked ? "enabled" : "disabled";
    setCallType(value);
    try {
      await update({
        key: "call",
        value
      });
      toast.success("Parâmetro atualizado com sucesso.");
    } catch (err) {
      setCallType(callType);
      toast.error("Erro ao atualizar parâmetro.");
    }
  };

  async function handleChatbotType(value) {
    setChatbotType(value);
    await update({
      key: "chatBotType",
      value
    });
    toast.success("Parâmetro atualizado com sucesso.");
  }

  const handleGroupTypeChange = async e => {
    const { checked } = e.target;
    const value = checked ? "enabled" : "disabled";
    setCheckMsgIsGroupType(value);
    try {
      await update({
        key: "CheckMsgIsGroup",
        value
      });
      toast.success("Parâmetro atualizado com sucesso.");
    } catch (err) {
      setCheckMsgIsGroupType(CheckMsgIsGroup);
      toast.error("Erro ao atualizar parâmetro.");
    }
  };

  const handleAddAlterConnectionChange = async e => {
    const { checked } = e.target;
    const value = checked ? "enabled" : "disabled";
    setAddAlterConnection(value);
    try {
      await update({
        key: "addAlterConnection",
        value
      });
      toast.success("Parâmetro atualizado com sucesso.");
    } catch (err) {
      setAddAlterConnection(addAlterConnection);
      toast.error("Erro ao atualizar parâmetro.");
    }
  };

  const handleUserViewTicketsWithoutDepartmentChange = async e => {
    const { checked } = e.target;
    const value = checked ? "enabled" : "disabled";
    setUserViewTicketsWithoutDepartment(value);
    try {
      await update({
        key: "userViewTicketsWithoutDepartment",
        value
      });
      toast.success("Parâmetro atualizado com sucesso.");
    } catch (err) {
      setUserViewTicketsWithoutDepartment(userViewTicketsWithoutDepartment);
      toast.error("Erro ao atualizar parâmetro.");
    }
  };

  const handleNewLeadsNotWriteNameChange = async e => {
    const { checked } = e.target;
    const value = checked ? "enabled" : "disabled";
    setNewLeadsNotWriteName(value);
    try {
      await update({
        key: "newLeadsNotWriteName",
        value
      });
      toast.success("Parâmetro atualizado com sucesso.");
    } catch (err) {
      setNewLeadsNotWriteName(newLeadsNotWriteName);
      toast.error("Erro ao atualizar parâmetro.");
    }
  };

  const handleUserAlwaysSubmitSignatureChange = async e => {
    const { checked } = e.target;
    const value = checked ? "enabled" : "disabled";
    setUserAlwaysSubmitSignature(value);
    try {
      await update({
        key: "userAlwaysSubmitSignature",
        value
      });
      toast.success("Parâmetro atualizado com sucesso.");
    } catch (err) {
      setUserAlwaysSubmitSignature(userAlwaysSubmitSignature);
      toast.error("Erro ao atualizar parâmetro.");
    }
  };
 
  async function handleChangeWebhook(value) {
    setWebhookType(value);
    await update({
      key: "webhook",
      value
    });
    toast.success("Parâmetro atualizado com sucesso.");
  }

  const handleChangeSendEmailEnabled = async e => {
    const { checked } = e.target;
    const value = checked ? "enabled" : "disabled";
    setSendEmailEnabled(value);
    try {
      await update({
        key: "sendEmailEnabled",
        value
      });
      toast.success("Parâmetro atualizado com sucesso.");
    } catch (err) {
      setSendEmailEnabled(sendEmailEnabled);
      toast.error("Erro ao atualizar parâmetro.");
    }
  };

  async function handleChangeGeneric(key, value, setStateFunction) {
    setStateFunction(value);
    await update({
      key,
      value
    });
    toast.success("Parâmetro atualizado com sucesso.");
  }

  return (
    <>
      <Grid spacing={3} container>
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={userRating === "enabled"}
                  onChange={handleUserRatingChange}
                />
              }
              label="Avaliação de atendimento"
            />
            <FormHelperText>
              {userRating === "enabled"
                ? "As avaliações estão habilitadas."
                : "As avaliações estão desabilitadas."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <FormControlLabel
              control={
                <IOSSwitch
                  disabled={true} // remover
                  checked={CheckMsgIsGroup === "enabled"}
                  onChange={handleGroupTypeChange}
                />
              }
              label="Ignorar mensagens de grupos"
            />
            <FormHelperText>
              {CheckMsgIsGroup === "enabled"
                ? "As mensagens de grupos estão sendo ignoradas."
                : "As mensagens de grupos estão sendo exibidas."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={callType === "enabled"}
                  onChange={handleCallTypeChange}
                />
              }
              label="Aceitar chamada"
            />
            <FormHelperText>
              {callType === "enabled"
                ? "As chamadas serão aceitas."
                : "As chamadas não serão aceitas."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={addAlterConnection === "enabled"}
                  onChange={handleAddAlterConnectionChange}
                />
              }
              label="Usuário tem acesso às conexões"
            />
            <FormHelperText>
              {addAlterConnection === "enabled"
                ? "Usuários tem acesso concedido."
                : "Usuários não tem acesso."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={userViewTicketsWithoutDepartment === "enabled"}
                  onChange={handleUserViewTicketsWithoutDepartmentChange}
                />
              }
              label="Usuário tem acesso a tickets sem fila"
            />
            <FormHelperText>
              {userViewTicketsWithoutDepartment === "enabled"
                ? "Usuários tem acesso concedido."
                : "Usuários não tem acesso."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={newLeadsNotWriteName === "enabled"}
                  onChange={handleNewLeadsNotWriteNameChange}
                />
              }
              label="Número ao invés de nome nos leads"
            />
            <FormHelperText>
              {newLeadsNotWriteName === "enabled"
                ? "Novos leads terão número ao invés de nome."
                : "Novos leads a plataforma tentará buscar o nome."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={userAlwaysSubmitSignature === "enabled"}
                  onChange={handleUserAlwaysSubmitSignatureChange}
                />
              }
              label="Sempre enviar assinatura do usuário"
            />
            <FormHelperText>
              {userAlwaysSubmitSignature === "enabled"
                ? "O usuário sempre enviará a assinatura."
                : "O usuário pode optar por enviar ou não."}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6} md={4} item>
          <Paper className={classes.paper} elevation={12}>
            <FormControl className={classes.selectContainer}>
              <InputLabel id="schedule-type-label">
                Horário de expediente
              </InputLabel>
              <Select
                labelId="schedule-type-label"
                value={scheduleType}
                onChange={async e => {
                  handleScheduleType(e.target.value);
                }}
              >
                <MenuItem value={"disabled"}>Desabilitado</MenuItem>
                <MenuItem value={"company"}>Controlar por empresa</MenuItem>
                <MenuItem value={"queue"}>Controlar por fila</MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </Grid>
        <Grid xs={12} sm={6} md={4} item>
          <Paper className={classes.paper} elevation={12}>
            <FormControl className={classes.selectContainer}>
              <InputLabel id="chatbot-type-label">Tipo chatbot</InputLabel>
              <Select
                labelId="chatbot-type-label"
                value={chatbotType}
                // disabled={true} //REMOVER PARA PERMITIR USAR LISTAS E BOTÕES
                onChange={async e => {
                  handleChatbotType(e.target.value);
                }}
              >
                <MenuItem value={"text"}>Texto</MenuItem>
                <MenuItem value={"button"}>{`Botão (Experimental)`}</MenuItem>
                <MenuItem value={"list"}>{`Lista (Experimental)`}</MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>
      
      <OnlyForSuperUser
        user={currentUser}
        yes={() => (
          <Accordion
            className={classes.root}
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>
                Gateway de Pagamento Efí
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: "flex", flexWrap: "wrap" }}>
              <Grid xs={12} sm={12} md={12} item style={{ marginBottom: 20 }}>
                <FormControlLabel
                  className="formControlLabel"
                  control={
                    <IOSSwitch
                      checked={efiSandbox === "enabled"}
                      onChange={handleEfiSandboxChange}
                    />
                  }
                  label="Sandbox"
                />
                <FormHelperText>
                  {efiSandbox === "enabled"
                    ? "Modo teste está ativado."
                    : "Modo produção ativado."}
                </FormHelperText>
              </Grid>

              <Grid spacing={3} container style={{ marginBottom: 10 }}>
                <Grid xs={12} sm={6} md={6} item>
                  <FormControl className={classes.selectContainer}>
                    <TextField
                      margin="dense"
                      label="Client ID"
                      variant="outlined"
                      value={efiClientId}
                      onChange={async e => {
                        handleChangeEfiClientId(e.target.value);
                      }}
                    ></TextField>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={6} md={6} item>
                  <FormControl className={classes.selectContainer}>
                    <TextField
                      margin="dense"
                      label="Client Secret"
                      variant="outlined"
                      value={efiClientSecret}
                      onChange={async e => {
                        handleChangeEfiClientSecret(e.target.value);
                      }}
                    ></TextField>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid spacing={3} container style={{ marginBottom: 10 }}>
                <Grid xs={12} sm={6} md={6} item>
                  <FormControl className={classes.selectContainer}>
                    <TextField
                      margin="dense"
                      label="Chave do PIX"
                      variant="outlined"
                      value={efiChavePix}
                      onChange={async e => {
                        handleChangeEfiChavePix(e.target.value);
                      }}
                    ></TextField>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={6} md={6} item>
                  <FormControl className={classes.selectContainer}>
                    <FileInput label="Certificado .p12" fileType=".p12" />
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      />
      <OnlyForSuperUser
        user={currentUser}
        yes={() => (
          <Accordion
            className={classes.root}
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>
                Facebook/Instagram
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: "flex", flexWrap: "wrap" }}>
              <Grid spacing={3} container style={{ marginBottom: 10 }}>
                <Grid xs={12} sm={6} md={6} item>
                  <FormControl className={classes.selectContainer}>
                    <TextField
                      margin="dense"
                      label="Facebook Client ID"
                      variant="outlined"
                      value={facebookClientId}
                      onChange={async e => {
                        handleChangeFacebookClientId(e.target.value);
                      }}
                    ></TextField>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={6} md={6} item>
                  <FormControl className={classes.selectContainer}>
                    <TextField
                      margin="dense"
                      label="Facebook Client Secret"
                      variant="outlined"
                      value={facebookClientSecret}
                      onChange={async e => {
                        handleChangeFacebookClientSecret(e.target.value);
                      }}
                    ></TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      />
      <OnlyForSuperUser
        user={currentUser}
        yes={() => (
          <Accordion
            className={classes.root}
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>
                API de Email SendGrid
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: "flex", flexWrap: "wrap" }}>
              <Grid spacing={3} container style={{ marginBottom: 10 }}>
                <Grid xs={12} sm={12} md={4} item style={{ marginBottom: 20 }}>
                  <FormControlLabel
                    className="formControlLabel"
                    control={
                      <IOSSwitch
                        checked={sendEmailEnabled === "enabled"}
                        onChange={handleChangeSendEmailEnabled}
                      />
                    }
                    label="Envio de emails"
                  />
                  <FormHelperText>
                    {sendEmailEnabled === "enabled"
                      ? "O envio de emails está ativado."
                      : "O envio de emails não está ativo."}
                  </FormHelperText>
                </Grid>
                <Grid xs={12} sm={12} md={8} item>
                  <FormControl className={classes.selectContainer}>
                    <TextField
                      margin="dense"
                      label="API KEY SendGrid"
                      variant="outlined"
                      value={apiKeySendGrid}
                      onChange={async e => {
                        handleChangeGeneric(
                          "apiKeySendGrid",
                          e.target.value,
                          setApiKeySendGrid
                        );
                      }}
                    ></TextField>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid spacing={3} container style={{ marginBottom: 10 }}>
                <Grid xs={12} sm={12} md={4} item>
                  <FormControl className={classes.selectContainer}>
                    <TextField
                      margin="dense"
                      label="Template ID - Cadastro de Cliente (Lead)"
                      variant="outlined"
                      value={idEmailRegister}
                      onChange={async e => {
                        handleChangeGeneric(
                          "idEmailRegister",
                          e.target.value,
                          setIdEmailRegister
                        );
                      }}
                    ></TextField>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={4} item>
                  <FormControl className={classes.selectContainer}>
                    <TextField
                      margin="dense"
                      label="Template ID - Cadastro de Usuário (Convite)"
                      variant="outlined"
                      value={idEmailInvite}
                      onChange={async e => {
                        handleChangeGeneric(
                          "idEmailInvite",
                          e.target.value,
                          setIdEmailInvite
                        );
                      }}
                    ></TextField>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={4} item>
                  <FormControl className={classes.selectContainer}>
                    <TextField
                      margin="dense"
                      label="Template ID - Redefinição de Senha"
                      variant="outlined"
                      value={idEmailRecoveryPassword}
                      onChange={async e => {
                        handleChangeGeneric(
                          "idEmailRecoveryPassword",
                          e.target.value,
                          setIdEmailRecoveryPassword
                        );
                      }}
                    ></TextField>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid spacing={3} container style={{ marginBottom: 10 }}>
                <Grid xs={12} sm={12} md={4} item>
                  <FormControl className={classes.selectContainer}>
                    <TextField
                      margin="dense"
                      label="Template ID - Envio da Fatura"
                      variant="outlined"
                      value={idEmailSendInvoice}
                      onChange={async e => {
                        handleChangeGeneric(
                          "idEmailSendInvoice",
                          e.target.value,
                          setIdEmailSendInvoice
                        );
                      }}
                    ></TextField>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={4} item>
                  <FormControl className={classes.selectContainer}>
                    <TextField
                      margin="dense"
                      label="Template ID - Fatura Paga"
                      variant="outlined"
                      value={idEmailInvoicePaid}
                      onChange={async e => {
                        handleChangeGeneric(
                          "idEmailInvoicePaid",
                          e.target.value,
                          setIdEmailInvoicePaid
                        );
                      }}
                    ></TextField>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={4} item>
                  <FormControl className={classes.selectContainer}>
                    <TextField
                      margin="dense"
                      label="Template ID - Fatura Atrasada"
                      variant="outlined"
                      value={idEmailOverdueInvoice}
                      onChange={async e => {
                        handleChangeGeneric(
                          "idEmailOverdueInvoice",
                          e.target.value,
                          setIdEmailOverdueInvoice
                        );
                      }}
                    ></TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      />
      <Accordion
        className={classes.root}
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Webhook</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: "flex", flexWrap: "wrap" }}>
          <Grid spacing={3} container style={{ marginBottom: 10 }}>
            <Grid xs={12} sm={12} md={12} item>
              <FormControl className={classes.selectContainer}>
                <TextField
                  margin="dense"
                  label="Webhook (Método POST)"
                  variant="outlined"
                  value={webhookType}
                  onChange={async e => {
                    handleChangeWebhook(e.target.value);
                  }}
                ></TextField>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
